import { Stack } from '@mui/material'
import { BreadcrumbBar } from 'componix'
import { useParams } from 'react-router-dom'
import { useGetPolicySummaryDetails } from '../cache/policyCache'
import { useGetUnitStatDetails } from '../cache/unitStatCache'
import { useGetWorkItemDetails } from '../cache/workQueueCache'
import WorkItemManualBanner from '../components/Banners/WorkItemManualCloseBanner'
import PolicyInfoGutterCard from '../components/ClaimDetails/PolicyInfoGutterCard'
import UnitStatInfoGutterCard from '../components/ClaimDetails/UnitStatInfoGutterCard'
import LayoutWithSideRail from '../components/LayoutWithSideRail'
import { PolicyCarrierErrorNotification } from '../components/WorkItemDetails/CarrierErrors/PolicyCarrierErrorNotification'
import { USRCarrierErrorNotification } from '../components/WorkItemDetails/CarrierErrors/USRCarrierErrorNotification'
import WorkItemDetailsCard from '../components/WorkItemDetails/WorkItemDetailsCard'
import { useNavbar } from '../contexts/NavbarContext'
import useSetAlertDetails from '../hooks/workQueue/useSetAlertDetails'

export const CarrierErrorNotifications = () => {
  const { workItemId } = useParams()
  const { marginLeft } = useNavbar()

  const {
    data: workItemDetails,
    isPending: isWorkItemDetailsLoading,
    isError: isWorkItemDetailsError,
  } = useGetWorkItemDetails(workItemId as string)

  const {
    data: policySummaryData,
    isLoading: isPolicySummaryLoading,
    isError: isPolicySummaryError,
  } = useGetPolicySummaryDetails(workItemDetails?.associatedEntities?.policyID as string)

  const {
    data: unitStatData,
    isLoading: isUnitStatLoading,
    isError: isUnitStatError,
  } = useGetUnitStatDetails(workItemDetails?.associatedEntities?.unitStatID as string)

  useSetAlertDetails([isWorkItemDetailsError, isPolicySummaryError, isUnitStatError])

  const content = (
    <Stack>
      {workItemDetails?.isClosedManual && <WorkItemManualBanner />}
      {workItemDetails?.workItemType?.includes('Policy') ? (
        <PolicyCarrierErrorNotification workItem={workItemDetails} />
      ) : (
        <USRCarrierErrorNotification workItem={workItemDetails} />
      )}
    </Stack>
  )

  return (
    <div
      style={{
        transition: 'margin-left 0.2s',
        marginLeft: marginLeft,
        marginRight: '24px',
        marginTop: '80px',
      }}
    >
      <BreadcrumbBar
        crumbs={[
          {
            label: `Work Queue`,
            to: `/workqueue`,
          },
          {
            label: `Work Item ${workItemDetails?.workItemNumber}`,
            to: `/workqueue/carrier-errors/${workItemId}`,
          },
        ]}
      />
      <LayoutWithSideRail
        mainContent={content}
        sideRailComponentArray={[
          <WorkItemDetailsCard key={'wid'} data={workItemDetails} isLoading={isWorkItemDetailsLoading} />,
          <PolicyInfoGutterCard
            key="pigc"
            policy={policySummaryData}
            guid={workItemDetails?.associatedEntities?.policyID}
            isLoading={isPolicySummaryLoading}
          />,
          <UnitStatInfoGutterCard
            key="usigc"
            unitStat={unitStatData}
            uuid={workItemDetails?.associatedEntities?.unitStatID}
            isLoading={isUnitStatLoading}
          />,
        ]}
      />
    </div>
  )
}

export default CarrierErrorNotifications
