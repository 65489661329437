import { InfoOutlined } from '@mui/icons-material'
import { Box } from '@mui/material'
import Alert from '@mui/material/Alert'
import Typography from '@mui/material/Typography'
import { AlertSeverity } from '../AlertNotification/AlertNotification'

const WorkItemManualBanner = () => {
  return (
    <Box pb={1}>
      <Alert key={'alert'} variant="filled" icon={<InfoOutlined />} severity={AlertSeverity.INFO}>
        <Typography>Manually Closed</Typography>
      </Alert>
    </Box>
  )
}
export default WorkItemManualBanner
