import { CalendarToday } from '@mui/icons-material'
import { Grid, Paper } from '@mui/material'
import { BreadcrumbBar, FieldGroup, PhoenixBaseCard, PhoenixLink, StatusBadge } from 'componix'
import { UUID } from 'crypto'
import { useParams } from 'react-router-dom'
import { useGetAncestors } from '../cache/ancestorCache'
import {
  useGetUnitStatDeductible,
  useGetUnitStatDetails,
  useGetUnitStatPolicyConditions,
  useGetUnitStatPolicyInfo,
  useGetUnitStatPolicyType,
} from '../cache/unitStatCache'
import { ExperienceRating } from '../components/ExperienceRating'
import PrimaryCoverageIdentifier from '../components/GroupIdentifier'
import LayoutWithSideRail from '../components/LayoutWithSideRail'
import PolicyConditions from '../components/PolicyConditions'
import RelateCoverageIDsCard from '../components/RelatedCoverageIDs'
import UnitStatErrorBanner from '../components/UnitStat/UnitStatErrorBanner'
import UnitStatExposure from '../components/UnitStatExposure/UnitStatExposure'
import LossRecordsSplit from '../components/UnitStatLossRecords/UnitStatLossRecords'
import UnitStatReportsErrors from '../components/UnitStatReports/UnitStatReportsErrors'
import { useNavbar } from '../contexts/NavbarContext'
import useSetAlertDetails from '../hooks/workQueue/useSetAlertDetails'
import { HierarchyTypes } from '../utils/hierarchy.enum'
import { formatMoney } from '../utils/moneyFormatter'
import { formatPercent } from '../utils/percentFormatter'

export const UnitStatDetails: React.FC = () => {
  const { unitStatId } = useParams()
  const { marginLeft } = useNavbar()

  const { data: ancestorData } = useGetAncestors(unitStatId as UUID, HierarchyTypes.UNITSTAT)

  const {
    data: unitStatDetailsData,
    isPending: isUnitStatDetailsLoading,
    isError: isUnitStatDetailsError,
  } = useGetUnitStatDetails(unitStatId as string)

  const {
    data: policyInfoData,
    isPending: isPolicyInfoLoading,
    isError: isPolicyInfoError,
  } = useGetUnitStatPolicyInfo(unitStatId as string)

  const {
    data: unitStatConditionsData,
    isPending: isUnitStatConditionsLoading,
    isError: isUnitStatConditionsError,
  } = useGetUnitStatPolicyConditions(unitStatId as string)

  const {
    data: unitStatPolicyTypeData,
    isLoading: isPolicyTypeLoading,
    isError: isPolicyTypeError,
    refetch: refetchPolicyType,
  } = useGetUnitStatPolicyType(unitStatId as string)

  const {
    data: unitStatDeductibleData,
    isLoading: isDeductibleLoading,
    isError: isDeductibleError,
    refetch: refetchDeductible,
  } = useGetUnitStatDeductible(unitStatId as string)

  const isLoadingData = isUnitStatDetailsLoading || isPolicyInfoLoading || isUnitStatConditionsLoading

  useSetAlertDetails([
    isUnitStatDetailsError,
    isUnitStatConditionsError,
    isPolicyInfoError,
    isPolicyTypeError,
    isDeductibleError,
  ])

  return (
    <div style={{ transition: 'margin-left 0.2s', marginLeft: marginLeft, marginRight: '24px', marginTop: '80px' }}>
      <BreadcrumbBar
        crumbs={[
          {
            label: `Combo ${ancestorData?.combo?.identifier as string}`,
            to: `/combos/${ancestorData?.combo?.guid}`,
          },
          {
            label: `Coverage ${ancestorData?.coverage?.identifier}`,
            to: `/coverages/${ancestorData?.coverage?.identifier}`,
          },
          {
            label: `Policy ${ancestorData?.policy?.identifier}`,
            to: `/policies/${ancestorData?.policy?.guid}`,
          },
          {
            label: `Unit Stat Report ${ancestorData?.unitStat?.identifier}`,
            to: `/unitstats/${ancestorData?.unitStat?.guid}`,
          },
        ]}
      />
      <LayoutWithSideRail
        mainContent={
          <>
            <UnitStatErrorBanner outstandingErrors={unitStatDetailsData?.outstandingErrors} isLoading={isLoadingData} />
            <PhoenixBaseCard cardTitle="Unit Stat Details" contentPadded={true} isLoading={isLoadingData}>
              <Grid container padding={'8px'} spacing={1}>
                <Grid item xs={3}>
                  <FieldGroup
                    label="STATUS"
                    value={
                      <Paper sx={{ marginTop: '4px' }} elevation={0}>
                        <StatusBadge description={unitStatDetailsData?.status} />
                      </Paper>
                    }
                  />
                </Grid>
                <Grid item xs={3}>
                  <FieldGroup
                    label="RECEIVED DATE"
                    value={unitStatDetailsData?.receivedDate}
                    icon={<CalendarToday sx={{ paddingRight: '4px' }} />}
                  />
                </Grid>
                <Grid item xs={3}>
                  <FieldGroup
                    label="ACCEPTED DATE"
                    value={unitStatDetailsData?.acceptedDate}
                    icon={<CalendarToday sx={{ paddingRight: '4px' }} />}
                  />
                </Grid>
              </Grid>
              <PhoenixBaseCard cardTitle="Policy Information" variantType="Secondary" isLoading={isLoadingData}>
                <Grid container>
                  <Grid item padding={1.5} marginLeft={0} xs={3}>
                    <FieldGroup label={'REPORT LEVEL'} value={policyInfoData?.reportLevel} />
                  </Grid>
                  <Grid item padding={1} xs={3}>
                    <FieldGroup label={'CORRECTION SEQ'} value={policyInfoData?.correctionSequence} />
                  </Grid>
                  <Grid item padding={1} xs={3}>
                    <FieldGroup label={'CORRECTION TYPE'} value={policyInfoData?.correctionType} />
                  </Grid>
                  <Grid item padding={1} xs={3}>
                    <FieldGroup label={'CARRIER ID'} value={policyInfoData?.carrierID} />
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item padding={1.5} marginLeft={0} xs={3}>
                    <FieldGroup
                      label={'POLICY NUMBER'}
                      value={
                        policyInfoData?.policyNumber && (
                          <PhoenixLink
                            to={`/policies/${ancestorData?.policy?.guid}`}
                            value={policyInfoData.policyNumber}
                          />
                        )
                      }
                    />
                  </Grid>
                  <Grid item padding={1} xs={3}>
                    <FieldGroup
                      label={'POLICY EFFECTIVE DATE'}
                      value={policyInfoData?.policyEffectiveDate}
                      icon={<CalendarToday />}
                    />
                  </Grid>
                  <Grid item padding={1} xs={3}>
                    <FieldGroup
                      label={'POLICY EXPIRATION DATE'}
                      value={policyInfoData?.policyExpirationDate}
                      icon={<CalendarToday />}
                    />
                  </Grid>
                  <Grid item padding={1} xs={3}>
                    <FieldGroup
                      label={'STATE EFFECTIVE DATE'}
                      value={policyInfoData?.stateEffectiveDate}
                      icon={<CalendarToday />}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={4}>
                  <PolicyConditions isLoadingData={isLoadingData} unitStatConditionsData={unitStatConditionsData} />
                </Grid>
                <Grid item xs={4}>
                  <PhoenixBaseCard
                    cardTitle="Policy Type"
                    variantType="Secondary"
                    collapsible={true}
                    contentPadded={true}
                    onExpand={refetchPolicyType}
                    isLoading={isPolicyTypeLoading}
                  >
                    <Grid container columnSpacing={1} sx={{ marginLeft: '8px', marginTop: '16px' }}>
                      <Grid item xs={6}>
                        <FieldGroup label="COVERAGE TYPE" value={unitStatPolicyTypeData?.coverageType} />
                      </Grid>
                      <Grid item xs={6}>
                        <FieldGroup label="PLAN TYPE" value={unitStatPolicyTypeData?.planType} />
                      </Grid>
                      <Grid item xs={6}>
                        <FieldGroup label="NON STANDARD TYPE" value={unitStatPolicyTypeData?.nonStandardType} />
                      </Grid>
                    </Grid>
                  </PhoenixBaseCard>
                </Grid>
                <Grid item xs={4}>
                  <PhoenixBaseCard
                    cardTitle="Deductible"
                    variantType="Secondary"
                    collapsible={true}
                    contentPadded={true}
                    onExpand={refetchDeductible}
                    isLoading={isDeductibleLoading}
                  >
                    <Grid container sx={{ marginLeft: '8px', marginTop: '16px' }}>
                      <Grid item xs={6}>
                        <FieldGroup label="DEDUCTIBLE TYPE" value={unitStatDeductibleData?.deductibleType} />
                      </Grid>
                      <Grid item xs={6}>
                        <FieldGroup label="PLAN TYPE" value={unitStatDeductibleData?.appliedType} />
                      </Grid>
                      <Grid item xs={6}>
                        <FieldGroup label="DEDUCTIBLE PERCENT" value={formatPercent(undefined)} />
                      </Grid>
                      <Grid item xs={6}>
                        <FieldGroup
                          label="DEDUCTIBLE AMOUNT PER CLAIM/ACCIDENT"
                          value={formatMoney(unitStatDeductibleData?.deductibleAmountPer)}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <FieldGroup
                          label="DEDUCTIBLE AMOUNT - AGGREGATE"
                          value={formatMoney(unitStatDeductibleData?.deductibleAmountAggregate)}
                        />
                      </Grid>
                    </Grid>
                  </PhoenixBaseCard>
                </Grid>
              </PhoenixBaseCard>
            </PhoenixBaseCard>
            <UnitStatExposure uuid={unitStatId as string} />
            <LossRecordsSplit uuid={unitStatId as string} />
            <UnitStatReportsErrors uuid={unitStatId as string} />
          </>
        }
        sideRailComponentArray={[
          <PrimaryCoverageIdentifier key={'cgh'} combo={ancestorData?.combo} />,
          <ExperienceRating combo={ancestorData?.combo} key="er" />,
          <RelateCoverageIDsCard
            comboId={ancestorData?.combo?.guid}
            coverageId={ancestorData?.coverage?.guid}
            key="rcic"
          />,
        ]}
      ></LayoutWithSideRail>
    </div>
  )
}
export default UnitStatDetails
