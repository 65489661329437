import { Box, Button, Grid } from '@mui/material'
import { useQueryClient } from '@tanstack/react-query'
import { ErrorAttachment, PhoenixBaseCard, PhoenixBaseTable, ResponseTimeline } from 'componix'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useGetCarrierErrorNotifications, useResolveCarrierError } from '../../../cache/workQueueCache'
import useAttachmentsModal from '../../../hooks/useAttachmentsModal'
import useErrorProcessingModal from '../../../hooks/useErrorProcessingModal'
import useSetAlertDetails from '../../../hooks/workQueue/useSetAlertDetails'
import { UpdateErrorsModel } from '../../../models/PolicyDetails/UpdateErrorsModel'
import WorkItemDetailsModel from '../../../models/WorkItemDetails/WorkItemDetailsModel'
import AcceptErrorModal from '../../Modals/AcceptErrorModal'
import AttachmentsModal from '../../Modals/AttachmentsModal'
import RejectErrorModal from '../../Modals/RejectErrorModal'
import { CarrierErrorColumns } from './CarrierErrorColumns'
import { USRCarrierErrorHeader } from './USRCarrierErrorHeader'

interface CarrierErrorNotificationsProps {
  workItem: WorkItemDetailsModel | undefined
}

export const USRCarrierErrorNotification = ({ workItem }: CarrierErrorNotificationsProps) => {
  const queryClient = useQueryClient()
  const navigate = useNavigate()

  const {
    isModalOpen: isAttachmentsModalOpen,
    openModal: openAttachmentsModal,
    closeModal: closeAttachmentsModal,
    currentAttachments: attachments,
  } = useAttachmentsModal()

  const {
    data: carrierErrorNotifications,
    isPending: isCarrierErrorNotificationsLoading,
    isError: isCarrierErrorNotificationsError,
  } = useGetCarrierErrorNotifications(workItem?.workQueue_WorkItemID as string)

  const {
    mutateAsync,
    isSuccess: isResolveCarrierErrorSuccess,
    isPending: isResolveCarrierErrorLoading,
    isError: isResolveCarrierErrorError,
  } = useResolveCarrierError(workItem?.workQueue_WorkItemID as string, queryClient)

  const updateUSRCarrierErrors = async (request: UpdateErrorsModel) => {
    return await mutateAsync({
      userAccepted: request.userAccepted,
      workItemGuid: workItem?.workQueue_WorkItemID as string,
      entityType: 'unitstat',
      responseText: request?.responseText,
    })
  }

  const {
    isModalOpen: isRejectModalOpen,
    openModal: openRejectModal,
    closeModal: closeRejectModal,
    rejectErrors,
  } = useErrorProcessingModal(updateUSRCarrierErrors)

  const {
    isModalOpen: isAcceptModalOpen,
    openModal: openAcceptModal,
    closeModal: closeAcceptModal,
    acceptErrors,
  } = useErrorProcessingModal(updateUSRCarrierErrors)

  useSetAlertDetails([isCarrierErrorNotificationsError, isResolveCarrierErrorError])

  useEffect(() => {
    if (isResolveCarrierErrorSuccess) navigate('/workqueue')
  }, [isResolveCarrierErrorSuccess, navigate])

  const error = carrierErrorNotifications?.error
  const errorExists = error && error?.errors.length > 0

  return (
    <PhoenixBaseCard contentPadded cardTitle="Carrier Error Notification">
      <PhoenixBaseCard
        isLoading={isCarrierErrorNotificationsLoading}
        cardTitle="USR Error Details"
        variantType="Secondary"
      >
        <USRCarrierErrorHeader error={error} />
        <PhoenixBaseTable columns={CarrierErrorColumns} data={error?.errors ?? []} />
        <Grid container>
          <Grid item xs={9}>
            <ResponseTimeline
              attachmentsFunc={function (rows: ErrorAttachment[]): void {
                openAttachmentsModal(rows)
              }}
              responses={carrierErrorNotifications?.communications ?? []}
            />
          </Grid>
          <Grid item xs={3}>
            {workItem?.workItemStatus === 'Open' && errorExists && (
              <Box sx={{ display: 'flex', gap: '0.5rem', mt: '22px' }}>
                <Button
                  color="success"
                  onClick={() => openAcceptModal([error?.errors[0].editID as string])}
                  variant="contained"
                  disabled={isResolveCarrierErrorLoading}
                >
                  Accept
                </Button>
                <Button
                  color="error"
                  onClick={() => openRejectModal([error?.errors[0].editID as string])}
                  variant="contained"
                  disabled={isResolveCarrierErrorLoading}
                >
                  Reject
                </Button>
              </Box>
            )}
          </Grid>
        </Grid>
      </PhoenixBaseCard>

      <AcceptErrorModal
        isOpen={isAcceptModalOpen}
        onCancel={closeAcceptModal}
        onAccept={() => acceptErrors(workItem?.workQueue_WorkItemID, 'unitstat')}
      />
      <RejectErrorModal
        isOpen={isRejectModalOpen}
        onCancel={closeRejectModal}
        onReject={(responseText: string) => rejectErrors(workItem?.workQueue_WorkItemID, 'unitstat', responseText)}
      />
      <AttachmentsModal isOpen={isAttachmentsModalOpen} onCancel={closeAttachmentsModal} attachments={attachments} />
    </PhoenixBaseCard>
  )
}
