import { InfoOutlined } from '@mui/icons-material'
import { Box } from '@mui/material'
import Alert from '@mui/material/Alert'
import Typography from '@mui/material/Typography'
import { AlertSeverity } from '../AlertNotification/AlertNotification'

interface PolicyErrorStatusBannerProps {
  hasOutstandingErrors: boolean | undefined
}

const PolicyErrorStatusBanner = ({ hasOutstandingErrors }: PolicyErrorStatusBannerProps) => {
  return hasOutstandingErrors ? (
    <Box pb={1}>
      <Alert key={'alert'} variant="filled" icon={<InfoOutlined />} severity={AlertSeverity.INFO}>
        <Typography>This policy is being tracked for errors</Typography>
      </Alert>
    </Box>
  ) : null
}

export default PolicyErrorStatusBanner
