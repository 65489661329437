import { PhoenixBaseTable } from 'componix'
import { PolicyDetailsPremiumModel } from '../../../models/PolicyDetails/PolicyDetailsPremiumModel'
import PolicyPremiumsTableColumns from './PolicyPremiumsTableColumns'

interface PolicyPremiumTableProps {
  data: PolicyDetailsPremiumModel[]
}

export const PolicyPremiumTable = ({ data }: PolicyPremiumTableProps) => {
  return <PhoenixBaseTable columns={PolicyPremiumsTableColumns} data={data} showPagination={false} />
}

export default PolicyPremiumTable
