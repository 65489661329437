import { PhoenixBaseCard } from 'componix'
import { ClaimDetailsResponse } from '../../models/ClaimDetails/ClaimDetailsResponse'
import { ClaimDetailsInformationCard } from './ClaimDetailsInformationCard'
import { ClaimDetailsInjuryDescriptionCodesCard } from './ClaimDetailsInjuryDescriptionCodesCard'
import { ClaimDetailsLossConditionsCard } from './ClaimDetailsLossConditionCard'
import { ClaimDetailsSummaryCard } from './ClaimDetailsSummaryCard'

interface ClaimDetailsContentProps {
  isLoading: boolean
  data: ClaimDetailsResponse | undefined
}

export const ClaimDetailsContent = ({ isLoading, data }: ClaimDetailsContentProps) => {
  return (
    <PhoenixBaseCard cardTitle={'Claim Details'} contentPadded>
      <ClaimDetailsSummaryCard data={data?.claimSummary} isLoading={isLoading} />
      <ClaimDetailsInformationCard data={data?.claimInformation} isLoading={isLoading} />
      <ClaimDetailsLossConditionsCard data={data?.lossConditions} isLoading={isLoading} />
      <ClaimDetailsInjuryDescriptionCodesCard data={data?.injuryDescriptionCodes} isLoading={isLoading} />
    </PhoenixBaseCard>
  )
}
