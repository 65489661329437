import { PhoenixBaseTable } from 'componix'
import React from 'react'
import PolicyDetailsEndorsementsModel from '../../../models/PolicyDetails/PolicyDetailsEndorsementsModel'
import PolicyEndorsementsTableColumns from './PolicyEndorsementsTableColumns'

interface PolicyEndorsementsTableProps {
  data: PolicyDetailsEndorsementsModel[]
  isLoading: boolean
}

export const PolicyEndorsementsTable = ({ data, isLoading }: PolicyEndorsementsTableProps) => {
  return <PhoenixBaseTable columns={PolicyEndorsementsTableColumns} data={data} isLoading={isLoading} />
}

export default PolicyEndorsementsTable
