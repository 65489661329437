import { ErrorDTO, StatusBadge } from 'componix'
import { MRT_ColumnDef } from 'material-react-table'

export const CarrierErrorColumns: MRT_ColumnDef<ErrorDTO>[] = [
  /* eslint react/prop-types: 0 */
  {
    accessorKey: 'errorMessage',
    header: 'Error Message',
    minSize: 240,
  },
  {
    accessorKey: 'editID',
    header: 'EDIT ID',
  },
  {
    accessorKey: 'status',
    header: 'STATUS',
    Cell: ({ cell }) => <StatusBadge description={cell.getValue<string>()} />,
    size: 10,
  },
]
