import api from '../utils/apiService'
import { Endpoints } from '../utils/endpoint'

export const downloadFileFromBlob = async (blobName: string, fileName: string) => {
  api
    .get(Endpoints.Attachments, { params: { blobName: blobName }, responseType: 'blob' })
    .then((response) => {
      const href = window.URL.createObjectURL(response.data)

      const anchorElement = document.createElement('a')

      anchorElement.href = href
      anchorElement.download = fileName

      document.body.appendChild(anchorElement)
      anchorElement.click()

      document.body.removeChild(anchorElement)
      window.URL.revokeObjectURL(href)
    })
    .catch((error) => {
      console.log('error: ', error)
    })
}
