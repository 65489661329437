import { InfoOutlined } from '@mui/icons-material'
import { Box } from '@mui/material'
import Alert from '@mui/material/Alert'
import Typography from '@mui/material/Typography'
import React from 'react'
import { AlertSeverity } from '../AlertNotification/AlertNotification'

const bannerVariants = {
  current: {
    message: 'This is the most current rating for this Rating Effective Date',
    severity: AlertSeverity.SUCCESS,
  },
  previous: {
    message: 'This rating has been superseded by a revision',
    severity: AlertSeverity.WARNING,
  },
}

export interface RatingDetailsBannerProps {
  ratingStatus: string | undefined
  hasBeenSuperseded: boolean | undefined
}

const RatingDetailsBanner = ({ ratingStatus, hasBeenSuperseded }: RatingDetailsBannerProps) => {
  const showBanner = ratingStatus === 'Issued'

  const bannerVariant = hasBeenSuperseded ? bannerVariants.previous : bannerVariants.current

  return showBanner ? (
    <Box p={0.5}>
      <Alert key={'alert'} variant="filled" icon={<InfoOutlined />} severity={bannerVariant.severity}>
        <Typography variant="body1" fontWeight={500}>
          {bannerVariant.message}
        </Typography>
      </Alert>
    </Box>
  ) : null
}

export default RatingDetailsBanner
