import { Email, Person } from '@mui/icons-material'
import { Grid } from '@mui/material'
import { FieldGroup, FieldGroupAddress, NoResultsFound, PhoenixBaseCard } from 'componix'
import PolicyDetailsProducerModel from '../../models/PolicyDetails/PolicyDetailsProducerModel'

interface PolicyDetailsProducerInformationCardProps {
  data: PolicyDetailsProducerModel | undefined
  isLoading?: boolean
}

export const PolicyDetailsProducerInformationCard = ({
  isLoading,
  data,
}: PolicyDetailsProducerInformationCardProps) => {
  const isInfoInvalid = (data: PolicyDetailsProducerModel | undefined) => {
    return data?.name === '' && Object.values(data?.address).every((value) => value === '')
  }

  if (isInfoInvalid(data)) {
    return (
      <PhoenixBaseCard
        variantType="Secondary"
        isLoading={isLoading}
        collapsible
        cardTitle="Producer Information"
        contentPadded
        defaultWidth={6}
      >
        <NoResultsFound padding={'8px'} />
      </PhoenixBaseCard>
    )
  }

  return (
    <PhoenixBaseCard
      variantType="Secondary"
      isLoading={isLoading}
      collapsible
      cardTitle="Producer Information"
      contentPadded
      defaultWidth={6}
    >
      <Grid container padding={'8px'}>
        <Grid item sm={12} md={10} lg={8}>
          <FieldGroup label="Agent" value={data?.name} icon={<Person />} multiLineWrap />
        </Grid>
        <Grid item sm={12} md={10} lg={8}>
          <FieldGroupAddress
            city={data?.address?.city}
            state={data?.address?.state}
            street={data?.address?.street}
            zipCode={data?.address?.zipCode}
          />
        </Grid>
        <Grid item sm={12} md={10} lg={8}>
          <FieldGroup label="Email" value={data?.email} icon={<Email />} />
        </Grid>
      </Grid>
    </PhoenixBaseCard>
  )
}
