const MINUTE = 1000 * 60

export enum StaleTime {
  ONE_MINUTE = MINUTE,
  FIVE_MINUTES = MINUTE * 5,
  TEN_MINUTES = MINUTE * 10,
  THIRTY_MINUTES = MINUTE * 30,
  ONE_HOUR = MINUTE * 60,
  SIX_HOURS = MINUTE * 60 * 6,
}
