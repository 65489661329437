import { Box, Button } from '@mui/material'
import { DataFieldBlankValue, PhoenixBaseTable, PhoenixLink } from 'componix'
import { MRT_ColumnDef } from 'material-react-table'
import { useMemo } from 'react'
import { useMatchPolicyToCoverage } from '../../hooks/workQueue/useMatchPolicyToCoverage'
import { UnmatchedTransactionModel } from '../../models/WorkItemDetails/UnmatchedTransactionModel'
import { handleTitleCase } from '../../utils/handleTitleCase'
import MatchPolicyToCoverageModal from './MatchPolicyToCoverageModal'

interface UnmatchedTransactionSummaryProps {
  data?: UnmatchedTransactionModel | null
  isLoading: boolean
}

const UnmatchedTransactionSummary = ({ data, isLoading }: UnmatchedTransactionSummaryProps) => {
  const {
    isModalOpen,
    closeModal,
    openModal,
    activeStep,
    handleConfirmMatch,
    isMatchLoading,
    setFoundMatch,
    match,
    successMatch,
  } = useMatchPolicyToCoverage()

  /* eslint react/prop-types: 0 */ /* istanbul ignore next */
  const columns = useMemo<MRT_ColumnDef<UnmatchedTransactionModel>[]>(
    () => [
      {
        accessorKey: 'combo',
        header: 'Combo ID',
        /* eslint-disable  @typescript-eslint/no-explicit-any */
        Cell: ({ row }) => (
          <>
            {row.original.combo?.guid ? (
              <PhoenixLink
                id={`Combo-${row.index}`}
                to={`/combos/${row.original.combo?.guid}`}
                value={row.original.combo?.identifier?.toString()}
              />
            ) : (
              <DataFieldBlankValue />
            )}
          </>
        ),
      },
      {
        accessorKey: 'coverage',
        header: 'Coverage ID',
        /* eslint-disable  @typescript-eslint/no-explicit-any */
        Cell: ({ row }) => (
          <>
            {row.original.coverage?.identifier ? (
              <PhoenixLink
                id={`Coverage-${row.index}`}
                to={`/coverages/${row.original.coverage?.identifier}`}
                value={row.original.coverage?.identifier?.toString()}
              />
            ) : (
              <DataFieldBlankValue />
            )}
          </>
        ),
      },
      {
        accessorKey: 'employer',
        header: 'Employer',
        Cell: ({ cell }: any) => {
          return handleTitleCase(cell.getValue())
        },
      },
      {
        accessorKey: 'address',
        header: 'Address',
        /* eslint-disable  @typescript-eslint/no-explicit-any */
        Cell: ({ row }: any) => {
          return row.original.address ? (
            <>
              {row.original.address.street ? handleTitleCase(row.original.address.street) + ', ' : ''}
              {row.original.address.city ? handleTitleCase(row.original.address.city) + ', ' : ''}
              {row.original.address.state ? handleTitleCase(row.original.address.state) + ', ' : ''}
              {row.original.address.zipCode ? handleTitleCase(row.original.address.zipCode.substring(0, 5)) : ''}
            </>
          ) : (
            <DataFieldBlankValue />
          )
        },
      },
      {
        accessorKey: 'fein',
        header: 'FEIN',
      },
      {
        accessorKey: 'isWorkItemOpen',
        header: '',
        /* eslint-disable  @typescript-eslint/no-explicit-any */
        Cell: ({ cell }: any) => {
          return (
            cell.getValue() && (
              <Box sx={{ textAlign: 'right' }}>
                <Button variant="contained" color="primary" size="small" onClick={() => openModal()}>
                  Find Match
                </Button>
              </Box>
            )
          )
        },
      },
    ],
    // eslint-disable-next-line
    []
  )

  return (
    <>
      <PhoenixBaseTable data={data ? [data] : []} columns={columns} isLoading={isLoading} />
      <MatchPolicyToCoverageModal
        data={data}
        isOpen={isModalOpen}
        handleClose={closeModal}
        activeStep={activeStep}
        match={match}
        successMatch={successMatch}
        setFoundMatch={setFoundMatch}
        handleConfirmMatch={handleConfirmMatch}
        isMatchLoading={isMatchLoading}
      />
    </>
  )
}

export default UnmatchedTransactionSummary
