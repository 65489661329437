import { PhoenixBaseCard } from 'componix'
import { UnmatchedTransactionModel } from '../../models/WorkItemDetails/UnmatchedTransactionModel'
import TransactionDetails from './TransactionDetails'
import UnmatchedTransactionSummary from './UnmatchedTransactionSummary'

interface UnmatchedTransactionProps {
  unmatchedTransaction: UnmatchedTransactionModel | undefined
  isLoading: boolean
}

const UnmatchedTransaction = ({ unmatchedTransaction, isLoading }: UnmatchedTransactionProps) => {
  return (
    <PhoenixBaseCard cardTitle="Policy Unmatched Transaction">
      <UnmatchedTransactionSummary data={unmatchedTransaction} isLoading={isLoading} />
      <TransactionDetails data={unmatchedTransaction} isLoading={isLoading} />
    </PhoenixBaseCard>
  )
}

export default UnmatchedTransaction
