import { useQuery } from '@tanstack/react-query'
import { downloadFileFromBlob } from '../services/downloadFilesService'
import { CacheKeys } from './cacheKeys'

const useDownloadBlob = (blobName: string, fileName: string) =>
  useQuery({
    queryKey: [CacheKeys.Attachments, blobName, fileName],
    queryFn: () => downloadFileFromBlob(blobName, fileName),
    enabled: false,
  })

export { useDownloadBlob }
