import { Apartment, Person, Work } from '@mui/icons-material'
import { Grid } from '@mui/material'
import { FieldGroup, FieldGroupAddress, NoResultsFound, PhoenixBaseCard } from 'componix'
import { PolicyDetailsPrimaryModel } from '../../models/PolicyDetails/PolicyDetailsPrimaryModel'
import { handleTitleCase } from '../../utils/handleTitleCase'

interface PolicyDetailsPrimaryNameCardProps {
  isLoading?: boolean
  handleFirstOpen?: () => void
  data: PolicyDetailsPrimaryModel | undefined
}

export const PolicyDetailsPrimaryNameCard = ({
  isLoading,
  data,
  handleFirstOpen,
}: PolicyDetailsPrimaryNameCardProps) => {
  const noData = data?.name == '' && Object.values(data?.address).every((value) => value == '')

  return (
    <PhoenixBaseCard
      variantType="Secondary"
      isLoading={isLoading}
      collapsible
      cardTitle="Primary Name"
      contentPadded
      defaultWidth={6}
      onExpand={handleFirstOpen}
    >
      {noData ? (
        <NoResultsFound padding={'8px'} />
      ) : (
        <Grid container padding={'8px'}>
          <Grid item sm={12} md={10} lg={8}>
            <FieldGroup label="Primary Name" value={data?.name} icon={<Person />} />
          </Grid>
          <Grid item sm={12} md={10} lg={8}>
            {data && (
              <FieldGroupAddress
                street={handleTitleCase(data?.address?.street)}
                state={handleTitleCase(data?.address?.state)}
                city={handleTitleCase(data?.address?.city)}
                zipCode={data?.address?.zipCode}
              />
            )}
          </Grid>
          <Grid item sm={12} md={10} lg={8}>
            <FieldGroup label="Legal Nature Code" value={data?.legalNature} icon={<Apartment />} />
          </Grid>
          <Grid item sm={12} md={10} lg={8}>
            <FieldGroup label="FEIN" value={data?.fein} icon={<Work />} />
          </Grid>
        </Grid>
      )}
    </PhoenixBaseCard>
  )
}
