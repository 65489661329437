import { Autocomplete, Button, CircularProgress, Dialog, DialogActions, DialogContent, TextField } from '@mui/material'
import { Fragment, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useWorkItemGetReassignUsers } from '../../cache/workQueueCache'
import WorkItemReassignUserModel from '../../models/WorkItemDetails/WorkItemReassignUser'
import PhoenixModalHeader from './PhoenixModalHeader'

interface IFormInput {
  userEmail: string
}

interface WorkItemReassignModalProps {
  isOpen: boolean
  onCancel: () => void
  onReassign: (userEmail: string) => void
}

const WorkItemReassignModal = ({ isOpen, onCancel, onReassign }: WorkItemReassignModalProps) => {
  const { handleSubmit, reset, formState } = useForm({
    defaultValues: {
      userEmail: '',
    },
  })

  const [person, setPerson] = useState<WorkItemReassignUserModel | null>(null)
  const { data: users, isPending: isUsersLoading } = useWorkItemGetReassignUsers(isOpen)

  const onSubmit: SubmitHandler<IFormInput> = () => {
    if (person?.email) {
      onReassign(person.email)
    }
    reset()
    onCancel()
  }

  const closeAndClear = () => {
    setPerson(null)
    onCancel()
    reset()
  }

  return (
    <Dialog fullWidth open={isOpen} maxWidth={'md'} data-testid={'reassign-modal'}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <PhoenixModalHeader title={'Reassign Work Item(s)'} isErrorColor={false} handleClose={closeAndClear} />
        <DialogContent>
          <Autocomplete
            data-testid={'userautocomplete'}
            isOptionEqualToValue={(option, value) => option.name === value.email}
            getOptionLabel={(option) => option.name}
            options={users ?? []}
            loading={isUsersLoading}
            onChange={(event, value) => {
              setPerson(value)
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Assign to"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <Fragment>
                      {isUsersLoading ? <CircularProgress color="inherit" size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </Fragment>
                  ),
                }}
              />
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button data-testid="cancel-manual-btn" size={'small'} variant="text" onClick={closeAndClear}>
            Cancel
          </Button>
          <Button
            disabled={person == null || formState.isSubmitting}
            size={'small'}
            variant="contained"
            color="primary"
            type="submit"
          >
            {formState.isSubmitting ? 'Submitting...' : 'REASSIGN'}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

export default WorkItemReassignModal
