import { DataFieldBlankValue } from 'componix'
import AddressDTOModel from '../models/AddressDTOModel'
import { handleTitleCase } from './handleTitleCase'

export const addressFormatter = (address?: AddressDTOModel) => {
  if (!address) return <DataFieldBlankValue />
  const street = handleTitleCase(address.street)
  const city = handleTitleCase(address.city)

  // Adds commas between correct address fields. Strips spaces if field is empty.
  return `${street ? street + ', ' : ''}${city ? city + ', ' : ''}${address.state ? address.state + ' ' : ''}${address.zipCode}`
}
