import { Close } from '@mui/icons-material'
import SearchIcon from '@mui/icons-material/Search'
import { Box, Button, CircularProgress, IconButton, InputAdornment, Stack, TextField } from '@mui/material'
import { PhoenixBaseCard } from 'componix'
import React, { useEffect, useState } from 'react'
import { useSearch } from '../../contexts/SearchContext'
import { NoAlphaCharacters, NoSpecialCharacters, NoSpecialCharactersWithExceptions } from '../../utils/formValidations'
import { SearchCategories } from '../../utils/searchCategories.enum'
import { Option } from '../SearchDropdown/SearchDropdown'
import SearchDropdown from '../SearchDropdown/SearchDropdown'

interface inputOptions {
  id: string
  placeholder: string
  pattern: string
  maxLength: number
  minLength?: number
}

interface SearchCategoryDetails {
  [key: string]: {
    optionParams: Option
    inputParams: inputOptions | null
  }
}
//Search Category Details for input fields, pattern, and max length
export const SearchCategoryDetails: SearchCategoryDetails = {
  [SearchCategories.ComboRatings]: {
    optionParams: {
      id: 'comboRatingsOption',
      value: SearchCategories.ComboRatings,
      label: SearchCategories.ComboRatings,
    },
    inputParams: { id: 'ComboRatings-input', placeholder: 'Combo ID', pattern: '', maxLength: 9 },
  },
  [SearchCategories.Coverage]: {
    optionParams: { id: 'coverageIdOption', value: SearchCategories.Coverage, label: SearchCategories.Coverage },
    inputParams: { id: 'Coverage-input', placeholder: 'Coverage ID', pattern: '[0-9]*', maxLength: 7 },
  },
  [SearchCategories.Policy]: {
    optionParams: { id: 'policyOption', value: SearchCategories.Policy, label: SearchCategories.Policy },
    inputParams: { id: 'Policy-input', placeholder: 'Policy Number', pattern: '[a-zA-Z0-9]*', maxLength: 18 },
  },
  [SearchCategories.Name]: {
    optionParams: { id: 'nameOption', value: SearchCategories.Name, label: SearchCategories.Name },
    inputParams: { id: 'Name-input', placeholder: 'Name', pattern: '[a-zA-Z0-9]*', maxLength: 90 },
  },
  [SearchCategories.FEIN]: {
    optionParams: { id: 'feinOption', value: SearchCategories.FEIN, label: SearchCategories.FEIN },
    inputParams: { id: 'FEIN-input', placeholder: 'FEIN', pattern: '[0-9]*', maxLength: 9, minLength: 5 },
  },
  [SearchCategories.undefined]: {
    optionParams: { id: '', value: '', label: '' },
    inputParams: null,
  },
}

const optionValues = Object.values(SearchCategoryDetails)
  .map((value) => value.optionParams)
  .filter((value) => value.value !== '')

export const SearchBar = () => {
  const [helperText, setHelperText] = useState<string>('')
  const [validationError, setValidationError] = useState<boolean>(false)
  const [isSearchEnabled, setIsSearchEnabled] = useState<boolean>(false)
  const {
    searchCategory,
    setSearchCategory,
    searchValue,
    setSearchResults,
    setSearchValue,
    startSearch,
    resetSearch,
    isSearching,
  } = useSearch()

  let modifiedInput = searchValue

  const resetValidation = () => {
    setValidationError(false)
    setHelperText('')
  }
  useEffect(() => {
    resetSearch()
  }, [])

  useEffect(() => {
    setIsSearchEnabled(searchCategory !== SearchCategories.undefined && searchValue !== '')
  }, [searchCategory, searchValue, setSearchValue, setValidationError, setHelperText, setIsSearchEnabled])

  const handleSelect = (event: { target: { value: string } }) => {
    setValidationError(false)
    setSearchCategory(event.target.value as SearchCategories)
    if (searchCategory !== (event.target.value as SearchCategories)) {
      resetValidation()
      setSearchValue('')
      setSearchResults(null)
    }
  }

  const handleSearchClicked = () => {
    const isValid = validateSearch()
    if (isValid) {
      startSearch(modifiedInput, searchCategory)
    }
  }

  const handleTextFieldChanged = (event: { target: { value: string } }) => {
    const inputValue = event.target.value
    if (ValidateNoSpecialCharacters(inputValue) === false) return
    resetValidation()
    switch (searchCategory) {
      case SearchCategories.Name:
      case SearchCategories.Policy:
        setSearchValue(inputValue.toUpperCase())
        break
      case SearchCategories.FEIN:
        setSearchValue(inputValue)
        if (inputValue.length != 5 && inputValue.length != 9) {
          setHelperText('Enter full 9-digit FEIN or last 5 digits.')
          return false
        } else {
          resetValidation()
        }
        break
      default:
        if (ValidateNoAlphaCharacters(inputValue) === false) return
        resetValidation()
        setSearchValue(inputValue)
        break
    }
  }

  const ValidateTrimAndStripLeadingZeros = (val: string) => {
    modifiedInput = val.replace(/^0+/, '').trim()
    if (modifiedInput.length === 0) {
      setHelperText('Please enter a value')
      setValidationError(true)
      return false
    }
    return true
  }

  const ValidateNoAlphaCharacters = (val: string) => {
    if (!NoAlphaCharacters(val)) {
      setHelperText('Non numeric characters are not allowed')
      setValidationError(true)
      setSearchValue(val.replace(/[^0-9]/g, ''))
      return false
    }
    return true
  }
  const ValidateNotOverMaxLength = (category: SearchCategories) => {
    const { inputParams } = SearchCategoryDetails[category]
    if (inputParams != null && modifiedInput.length > inputParams.maxLength) {
      const inputValue = modifiedInput.substring(0, inputParams.maxLength)
      setHelperText(`${inputParams.placeholder} must not contain more than ${inputParams.maxLength} characters.`)
      setValidationError(true)
      setSearchValue(inputValue)
      return false
    }
    return true
  }

  const ValidateNoSpecialCharacters = (input: string) => {
    let validationResults = false
    if (searchCategory === SearchCategories.Name) {
      validationResults = NoSpecialCharactersWithExceptions(input)
    } else {
      validationResults = NoSpecialCharacters(input)
    }
    if (!validationResults) {
      setHelperText('Special characters are not allowed')
      setValidationError(true)
      setSearchValue(input.replace(/[^a-zA-Z0-9]/g, ''))
      return false
    }
    return true
  }
  //Combo Ratings Input Validations
  const comboInputValidation = () => {
    if (ValidateTrimAndStripLeadingZeros(modifiedInput) === false) return false
    if (ValidateNoAlphaCharacters(modifiedInput) === false) return false
    return true
  }

  //Coverage ID Input Validations
  const coverageInputValidation = () => {
    if (ValidateTrimAndStripLeadingZeros(modifiedInput) === false) return false
    if (ValidateNoAlphaCharacters(modifiedInput) === false) return false
    return true
  }
  //FEIN Input Validations
  const feinInputValidation = () => {
    modifiedInput = modifiedInput.trim()
    if (ValidateNoAlphaCharacters(modifiedInput) === false) return false
    if (modifiedInput.length != 5 && modifiedInput.length != 9) {
      setHelperText('Enter full 9-digit FEIN or last 5 digits.')
      setValidationError(true)
      return false
    }
    return true
  }

  //General Validation for all search categories.
  //Special character validation is excluded for Name search category
  const validateSearch = () => {
    let isValid = true
    isValid = ValidateNotOverMaxLength(searchCategory) && ValidateNoSpecialCharacters(searchValue)
    if (isValid) {
      switch (searchCategory) {
        case SearchCategories.ComboRatings:
          isValid = comboInputValidation()
          break
        case SearchCategories.Coverage:
          isValid = coverageInputValidation()
          break
        case SearchCategories.FEIN:
          isValid = feinInputValidation()
          break
        case SearchCategories.Policy:
        case SearchCategories.Name:
          isValid = true
          break
        default:
          isValid = false
      }
    }
    setSearchValue(modifiedInput)
    return isValid
  }

  const renderAdditionalSearchFieldsByCategory = (category: SearchCategories) => {
    const { inputParams } = SearchCategoryDetails[category]
    if (category === SearchCategories.undefined || inputParams === null) {
      return <></>
    }
    return (
      <TextField
        margin="none"
        inputProps={{ maxLength: inputParams.maxLength, pattern: inputParams.pattern }}
        onKeyDown={(e) => {
          if (searchValue.length === inputParams.maxLength && e.key != 'Enter') {
            setHelperText(`${inputParams.placeholder} must not contain more than ${inputParams.maxLength}-digit value.`)
            setValidationError(true)
          } else {
            resetValidation()
          }
        }}
        value={searchValue}
        error={validationError}
        label={inputParams.placeholder}
        id={inputParams.id}
        InputProps={
          searchValue || searchValue.length > 0
            ? {
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      size="small"
                      id="ClearSearchValue-button"
                      aria-label="Clear Search Value"
                      onClick={() => {
                        setSearchValue('')
                        resetValidation()
                      }}
                      edge="end"
                    >
                      <Close />
                    </IconButton>
                  </InputAdornment>
                ),
              }
            : {}
        }
        helperText={helperText}
        onChange={handleTextFieldChanged}
      />
    )
  }

  return (
    <PhoenixBaseCard cardTitle="Search">
      <Box
        component="form"
        sx={{ '& > :not(style)': { m: 1, width: '25ch' } }}
        onSubmit={(e) => {
          e.preventDefault()
        }}
        autoComplete="off"
        noValidate
      >
        <SearchDropdown
          handleSelect={handleSelect}
          labelText={'Category'}
          value={searchCategory}
          data-testid="SearchCategoryDropdown"
          error={false}
          helperText={' '}
          options={optionValues}
        />
        {renderAdditionalSearchFieldsByCategory(searchCategory)}
        <Stack direction={'row'} spacing={2} padding="8px">
          <Button
            id={`Search-button`}
            type="submit"
            disabled={!isSearchEnabled || isSearching}
            startIcon={isSearching ? <CircularProgress size={15} /> : <SearchIcon />}
            onClick={handleSearchClicked}
            variant="contained"
          >
            Search
          </Button>
          <Button variant="text" type="reset" onClick={resetSearch}>
            Clear
          </Button>
        </Stack>
      </Box>
    </PhoenixBaseCard>
  )
}

export default SearchBar
