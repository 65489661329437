import { Button, Dialog, DialogActions, DialogContent, TextField } from '@mui/material'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import PhoenixModalHeader from './PhoenixModalHeader'

interface IFormInput {
  responseText: string
}

interface ManualCloseModalProps {
  isOpen: boolean
  onCancel: () => void
  onManualClose: (responseText: string) => void
}

const ManualCloseModal = ({ isOpen, onCancel, onManualClose }: ManualCloseModalProps) => {
  const { control, handleSubmit, register, reset, formState } = useForm({
    defaultValues: {
      responseText: '',
    },
  })

  const onSubmit: SubmitHandler<IFormInput> = (data) => {
    onManualClose(data.responseText)
    reset()
    onCancel()
  }

  const closeAndClear = () => {
    onCancel()
    reset()
  }

  return (
    <Dialog fullWidth open={isOpen} maxWidth={'md'} data-testid={'manualclose-modal'}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <PhoenixModalHeader title={'Manually Close Work Item(s)'} isErrorColor={false} handleClose={closeAndClear} />
        <DialogContent>
          <Controller
            name="responseText"
            control={control}
            rules={{ required: true, minLength: 20, maxLength: 250 }}
            render={({ field }) => (
              <TextField
                {...field}
                {...register('responseText', { required: true, minLength: 20, maxLength: 250 })}
                fullWidth
                label="Reason"
                multiline
                rows={3}
                InputLabelProps={{ shrink: true }}
                data-testid="response-text"
              />
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button data-testid="cancel-manual-btn" size={'small'} variant="text" onClick={closeAndClear}>
            Cancel
          </Button>
          <Button
            disabled={!formState.isValid || formState.isSubmitting}
            size={'small'}
            variant="contained"
            color="primary"
            type="submit"
          >
            {formState.isSubmitting ? 'Submitting...' : 'Confirm'}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

export default ManualCloseModal
