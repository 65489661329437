import { CreditCard } from '@mui/icons-material'
import { Divider, Grid } from '@mui/material'
import { FieldGroup, NoResultsFound, PhoenixBaseCard } from 'componix'
import React from 'react'
import { useGetUnitStatExposures } from '../../cache/unitStatCache'
import useSetAlertDetails from '../../hooks/workQueue/useSetAlertDetails'
import { formatMoney } from '../../utils/moneyFormatter'
import SplitComponent from './SplitComponent/SplitComponent'

interface ExposureProps {
  uuid: string
}

export const UnitStatExposure = ({ uuid }: ExposureProps) => {
  const {
    data: exposuresData,
    isLoading: isExposureLoading,
    isError: isExposureError,
    refetch: refetchExposure,
  } = useGetUnitStatExposures(uuid)

  useSetAlertDetails([isExposureError])

  return (
    <div style={{ marginTop: '8px' }}>
      <PhoenixBaseCard collapsible={true} onExpand={refetchExposure} cardTitle="Exposure" isLoading={isExposureLoading}>
        {!exposuresData?.splits?.length ? (
          <NoResultsFound />
        ) : (
          exposuresData.splits.map((splitObject, index) => {
            return <SplitComponent splitObject={splitObject} key={index} />
          })
        )}
        <Divider
          variant="fullWidth"
          sx={{
            margin: '8px 8px 4px 8px',
            width: '99%',
            borderColor: 'divider',
          }}
        />
        <div style={{ marginLeft: '4px', marginRight: '4px', width: '100%' }}>
          <PhoenixBaseCard variantType="Secondary" cardTitle={'Reported Totals'}>
            <Grid
              paddingLeft={'8px'}
              paddingTop={'8px'}
              flexDirection={'row'}
              container
              spacing={1}
              marginBottom={'16px'}
            >
              <Grid item sx={{ marginLeft: '16px', marginTop: '16px' }} xs={3.5}>
                <FieldGroup
                  label={'TOTAL SUBJECT PREMIUM AMOUNT'}
                  value={formatMoney(exposuresData?.totalSubjectPremium)}
                  icon={<CreditCard />}
                />
              </Grid>
              <Grid item sx={{ marginTop: '16px' }} xs={3.5}>
                <FieldGroup
                  label={'TOTAL STANDARD PAYROLL EXPOSURE'}
                  value={formatMoney(exposuresData?.totalStandardPayrollExposure)}
                  icon={<CreditCard />}
                />
              </Grid>
              <Grid item sx={{ marginLeft: '16px', marginTop: '16px' }} xs={4}>
                <FieldGroup
                  label={'TOTAL STANDARD PREMIUM AMOUNT'}
                  value={formatMoney(exposuresData?.totalStandardPremium)}
                  icon={<CreditCard />}
                />
              </Grid>
            </Grid>
          </PhoenixBaseCard>
        </div>
      </PhoenixBaseCard>
    </div>
  )
}
export default UnitStatExposure
