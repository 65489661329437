import { PhoenixBaseCard, PhoenixBaseTable } from 'componix'
import { useGetPolicyCancellationsReinstatements } from '../../cache/policyCache'
import useSetAlertDetails from '../../hooks/workQueue/useSetAlertDetails'
import { PolicyDetailsCancellationsColumns } from './PolicyDetailsCancellationsTable.tsx/PolicyDetailsCancellationsColumns'

interface PolicyCancellationsReinstatementsProps {
  uuid: string
}

export const PolicyCancellationsReinstatements = ({ uuid }: PolicyCancellationsReinstatementsProps) => {
  const { data, isLoading, isError, refetch } = useGetPolicyCancellationsReinstatements(uuid)
  useSetAlertDetails([isError])

  return (
    <PhoenixBaseCard
      cardTitle={'Cancellation/Reinstatements'}
      collapsible={true}
      onExpand={refetch}
      isLoading={isLoading}
    >
      <PhoenixBaseTable columns={PolicyDetailsCancellationsColumns} data={data ?? []} isLoading={isLoading} />
    </PhoenixBaseCard>
  )
}

export default PolicyCancellationsReinstatements
