import { QueryClient, useMutation, useQuery } from '@tanstack/react-query'
import MyWorkQueueDetailsModel from '../models/MyWorkQueueDetailsModel'
import { CarrierErrorNotification } from '../models/WorkItemDetails/CarrierErrorNotification'
import { MatchingCoverageResponse } from '../models/WorkItemDetails/MatchingCoverageResponse'
import { ResolveCarrierError } from '../models/WorkItemDetails/ResolveCarrierError'
import { SuggestedMatchModel } from '../models/WorkItemDetails/SuggestedMatchModel'
import { UnmatchedTransactionModel } from '../models/WorkItemDetails/UnmatchedTransactionModel'
import WorkItemDetailsModel from '../models/WorkItemDetails/WorkItemDetailsModel'
import { WorkQueueItemModel } from '../models/WorkQueueItemModel'
import {
  findMatchingCombo,
  findMatchingCoverage,
  getCarrierErrorNotifications,
  getSuggestedMatches,
  getUnmatchedTransaction,
  getWorkItemDetails,
  matchPolicyToCoverage,
  resolveCarrierError,
  workItemGetReassignUsers,
  workItemsManualClose,
  workItemsReassignUser,
} from '../services/workItemService'
import { getClosedWorkQueueItems, getMyWorkQueueDetails, getOpenWorkQueueItems } from '../services/workQueueService'
import { CacheKeys } from './cacheKeys'
import { StaleTime } from './staleTime'

const refetchWorkQueuePage = (queryClient: QueryClient) => {
  queryClient.fetchQuery({ queryKey: [CacheKeys.OpenWorkQueueItems], queryFn: getOpenWorkQueueItems })
  queryClient.fetchQuery({ queryKey: [CacheKeys.ClosedWorkQueueItems], queryFn: getClosedWorkQueueItems })
  queryClient.fetchQuery({ queryKey: [CacheKeys.WorkQueueDetails], queryFn: getMyWorkQueueDetails })
}

const useGetOpenWorkItems = () =>
  useQuery<WorkQueueItemModel[]>({
    queryKey: [CacheKeys.OpenWorkQueueItems],
    queryFn: () => getOpenWorkQueueItems(),
    staleTime: StaleTime.FIVE_MINUTES,
  })

const useGetClosedWorkItems = () =>
  useQuery<WorkQueueItemModel[]>({
    queryKey: [CacheKeys.ClosedWorkQueueItems],
    queryFn: () => getClosedWorkQueueItems(),
    staleTime: StaleTime.FIVE_MINUTES,
  })

const useGetWorkQueueDetails = () =>
  useQuery<MyWorkQueueDetailsModel>({
    queryKey: [CacheKeys.WorkQueueDetails],
    queryFn: () => getMyWorkQueueDetails(),
    staleTime: StaleTime.FIVE_MINUTES,
  })

const useGetWorkItemDetails = (workItemId: string) =>
  useQuery<WorkItemDetailsModel>({
    queryKey: [CacheKeys.WorkItemDetails, workItemId],
    queryFn: () => getWorkItemDetails(workItemId),
    staleTime: StaleTime.THIRTY_MINUTES,
    enabled: !!workItemId,
  })

const useGetUnmatchedTransaction = (workItemId: string) =>
  useQuery<UnmatchedTransactionModel>({
    queryKey: [CacheKeys.UnmatchedTransaction, workItemId],
    queryFn: () => getUnmatchedTransaction(workItemId),
    staleTime: StaleTime.THIRTY_MINUTES,
    enabled: !!workItemId,
  })

const useGetSuggestedMatches = (workItemId: string) =>
  useQuery<SuggestedMatchModel[]>({
    queryKey: [CacheKeys.SuggestedMatches, workItemId],
    queryFn: () => getSuggestedMatches(workItemId),
    staleTime: StaleTime.THIRTY_MINUTES,
    enabled: !!workItemId,
  })

const useGetCarrierErrorNotifications = (workItemId: string) =>
  useQuery<CarrierErrorNotification>({
    queryKey: [CacheKeys.CarrierErrorNotifications, workItemId],
    queryFn: () => getCarrierErrorNotifications(workItemId),
    staleTime: StaleTime.THIRTY_MINUTES,
    enabled: !!workItemId,
  })

const useFindMatchingCoverage = (coverageId: string) =>
  useQuery<MatchingCoverageResponse>({
    queryKey: [CacheKeys.FindMatchingCoverage, coverageId],
    queryFn: () => findMatchingCoverage(coverageId),
    staleTime: StaleTime.FIVE_MINUTES,
    enabled: false,
  })

const useFindMatchingCombo = (comboId: string) =>
  useQuery<MatchingCoverageResponse>({
    queryKey: [CacheKeys.FindMatchingCombo, comboId],
    queryFn: () => findMatchingCombo(comboId),
    staleTime: StaleTime.FIVE_MINUTES,
    enabled: false,
  })

const useWorkItemGetReassignUsers = (isOpen: boolean) =>
  useQuery({
    queryKey: [CacheKeys.WorkItemGetReassignUsers],
    queryFn: () => workItemGetReassignUsers(),
    staleTime: StaleTime.THIRTY_MINUTES,
    enabled: isOpen,
  })

// ====== MUTATIONS ======

const useResolveCarrierError = (workItemId: string, queryClient: QueryClient) =>
  useMutation({
    mutationKey: [CacheKeys.ResolveCarrierError, workItemId],
    mutationFn: ({ userAccepted, workItemGuid, entityType, responseText }: ResolveCarrierError) =>
      resolveCarrierError({ userAccepted, workItemGuid, entityType, responseText }),
    onSuccess: () => {
      refetchWorkQueuePage(queryClient)
      queryClient.invalidateQueries({ queryKey: [CacheKeys.WorkItemDetails, workItemId] })
      queryClient.invalidateQueries({ queryKey: [CacheKeys.CarrierErrorNotifications, workItemId] })
    },
  })

// appending with API to avoid naming conflicts
const useMatchPolicyToCoverageAPI = (
  workItemId: string,
  coverageId: string | undefined,
  comboId: string | undefined,
  queryClient: QueryClient
) =>
  useMutation({
    mutationKey: [CacheKeys.MatchPolicyToCoverage, workItemId, coverageId],
    mutationFn: () =>
      matchPolicyToCoverage(workItemId, {
        coverageId: coverageId || null,
        comboId: comboId || null,
      }),
    onSuccess: () => {
      refetchWorkQueuePage(queryClient)
      queryClient.invalidateQueries({ queryKey: [CacheKeys.WorkItemDetails, workItemId] })
      queryClient.invalidateQueries({ queryKey: [CacheKeys.UnmatchedTransaction, workItemId] })
      queryClient.invalidateQueries({ queryKey: [CacheKeys.SuggestedMatches, workItemId] })
    },
  })

const useWorkItemsManualClose = (workItemIds: string[], queryClient: QueryClient) =>
  useMutation({
    mutationKey: [CacheKeys.WorkItemsManualClose],
    mutationFn: (responseText?: string) => workItemsManualClose(workItemIds, responseText),
    onSuccess: () => {
      workItemIds.forEach((workItemId) => {
        queryClient.invalidateQueries({ queryKey: [CacheKeys.WorkItemDetails, workItemId] })
      })
      refetchWorkQueuePage(queryClient)
    },
  })

const useWorkItemsReassignUser = (workItemIds: string[], queryClient: QueryClient) =>
  useMutation({
    mutationKey: [CacheKeys.WorkItemsReassignUsers],
    mutationFn: (userEmail: string) => workItemsReassignUser(workItemIds, userEmail),
    onSuccess: () => {
      refetchWorkQueuePage(queryClient)
      workItemIds.forEach((workItemId) => {
        queryClient.invalidateQueries({ queryKey: [CacheKeys.WorkItemDetails, workItemId] })
      })
    },
  })

export {
  useGetWorkQueueDetails,
  useGetClosedWorkItems,
  useGetOpenWorkItems,
  useGetWorkItemDetails,
  useGetUnmatchedTransaction,
  useGetSuggestedMatches,
  useGetCarrierErrorNotifications,
  useFindMatchingCoverage,
  useFindMatchingCombo,
  useWorkItemGetReassignUsers,
  useResolveCarrierError,
  useMatchPolicyToCoverageAPI,
  useWorkItemsManualClose,
  useWorkItemsReassignUser,
}
