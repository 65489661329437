import { useQueryClient } from '@tanstack/react-query'
import { NoResultsFound, PhoenixBaseCard } from 'componix'
import { UUID } from 'crypto'
import { useGetPolicyErrors, useUpdatePolicyErrors } from '../../cache/policyCache'
import useAttachmentsModal from '../../hooks/useAttachmentsModal'
import useErrorProcessingModal from '../../hooks/useErrorProcessingModal'
import useSetAlertDetails from '../../hooks/workQueue/useSetAlertDetails'
import { UpdateErrorsModel } from '../../models/PolicyDetails/UpdateErrorsModel'
import AcceptErrorModal from '../Modals/AcceptErrorModal'
import AttachmentsModal from '../Modals/AttachmentsModal'
import RejectErrorModal from '../Modals/RejectErrorModal'
import { TransactionCard } from './TransactionCard'

export interface PolicyErrorProps {
  policyID: UUID
}

const PolicyErrors = ({ policyID }: PolicyErrorProps) => {
  const queryClient = useQueryClient()
  const {
    data: policyErrorsData,
    isLoading: isPolicyErrorsDataLoading,
    isError: isPolicyErrorsDataError,
    refetch: refetchPolicyErrorsData,
  } = useGetPolicyErrors(policyID)

  const { mutateAsync } = useUpdatePolicyErrors(policyID, queryClient)

  const updateErrors = async (request: UpdateErrorsModel) => {
    return await mutateAsync({
      editIDs: request.editIDs,
      userAccepted: request.userAccepted,
      entityType: 'policy',
      entityGuid: request.entityGuid as UUID,
      responseText: request?.responseText,
    })
  }

  const {
    isModalOpen: isAcceptModalOpen,
    openModal: openAcceptModal,
    closeModal: closeAcceptModal,
    acceptErrors,
  } = useErrorProcessingModal(updateErrors)

  const {
    isModalOpen: isRejectModalOpen,
    openModal: openRejectModal,
    closeModal: closeRejectModal,
    rejectErrors,
  } = useErrorProcessingModal(updateErrors)

  const {
    isModalOpen: isAttachmentsModalOpen,
    openModal: openAttachmentsModal,
    closeModal: closeAttachmentsModal,
    currentAttachments: attachments,
  } = useAttachmentsModal()

  useSetAlertDetails([isPolicyErrorsDataError])

  return (
    <>
      <PhoenixBaseCard
        cardTitle={'Policy Errors'}
        contentPadded
        collapsible
        onExpand={refetchPolicyErrorsData}
        isLoading={isPolicyErrorsDataLoading}
      >
        {policyErrorsData?.length ? (
          policyErrorsData?.map((error) => (
            <TransactionCard
              key={error.guid}
              error={error}
              isLoading={isPolicyErrorsDataLoading}
              openAcceptModal={openAcceptModal}
              openRejectModal={openRejectModal}
              openAttachments={openAttachmentsModal}
            />
          ))
        ) : (
          <NoResultsFound />
        )}
      </PhoenixBaseCard>
      <AcceptErrorModal
        isOpen={isAcceptModalOpen}
        onCancel={closeAcceptModal}
        onAccept={() => acceptErrors(policyID, 'policy')}
      />
      <RejectErrorModal
        isOpen={isRejectModalOpen}
        onCancel={closeRejectModal}
        onReject={(responseText: string) => rejectErrors(policyID, 'policy', responseText)}
      />
      <AttachmentsModal isOpen={isAttachmentsModalOpen} onCancel={closeAttachmentsModal} attachments={attachments} />
    </>
  )
}

export default PolicyErrors
