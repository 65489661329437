import { useQueryClient } from '@tanstack/react-query'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useMatchPolicyToCoverageAPI } from '../../cache/workQueueCache'
import { MatchSuccessResponse } from '../../models/WorkItemDetails/MatchSuccessResponse'
import { SuggestedMatchModel } from '../../models/WorkItemDetails/SuggestedMatchModel'
import { useBoolean } from '../useBoolean'
import useSetAlertDetails from './useSetAlertDetails'

export interface IUseMatchPolicyToCoverage {
  isModalOpen: boolean
  openModal: (suggestedTransaction?: SuggestedMatchModel) => void
  closeModal: () => void
  setFoundMatch: (match: SuggestedMatchModel | null) => void
  match: SuggestedMatchModel | null
  successMatch: MatchSuccessResponse | null
  isMatchLoading: boolean
  activeStep: number
  handleConfirmMatch: () => void
}

export const useMatchPolicyToCoverage = (): IUseMatchPolicyToCoverage => {
  const queryClient = useQueryClient()
  const { value: isModalOpen, onTrue, onFalse } = useBoolean()
  const [match, setMatch] = useState<SuggestedMatchModel | null>(null)
  const [successMatch, setSuccessMatch] = useState<MatchSuccessResponse | null>(null)
  const [activeStep, setActiveStep] = useState(0)
  const { workItemId = '' } = useParams()

  const {
    mutate: matchFunc,
    isPending: isMatchLoading,
    data: successMatchResponse,
    isError,
    isSuccess,
  } = useMatchPolicyToCoverageAPI(workItemId, match?.coverage.guid, match?.combo.guid, queryClient)

  useSetAlertDetails([isError])

  useEffect(() => {
    if (isSuccess) {
      setActiveStep(1)
      setSuccessMatch(successMatchResponse)
    }
  }, [isSuccess, setActiveStep, setSuccessMatch, successMatchResponse])

  const handleConfirmMatch = async () => {
    if (!match) return
    matchFunc()
  }

  const openModal = (suggestedTransaction?: SuggestedMatchModel) => {
    if (suggestedTransaction) {
      setMatch(suggestedTransaction)
    } else {
      setMatch(null)
    }
    setActiveStep(0)
    onTrue()
  }

  const closeModal = () => {
    onFalse()
  }

  const setFoundMatch = (match: SuggestedMatchModel | null) => {
    setMatch(match)
  }

  return {
    isModalOpen,
    openModal,
    closeModal,
    setFoundMatch,
    match,
    successMatch,
    isMatchLoading,
    activeStep,
    handleConfirmMatch,
  }
}
