import { QueryClient, useMutation, useQuery } from '@tanstack/react-query'
import { ExposureModel } from '../models/ExposureModel'
import { Policy } from '../models/Policy'
import PolicyDetailsCancellationsReintstatementsModel from '../models/PolicyDetails/PolicyDetailsCancellationsReintstatementsModel'
import PolicyEmployersLocationsModel from '../models/PolicyDetails/PolicyDetailsEmployerLocations'
import PolicyDetailsEndorsementsModel from '../models/PolicyDetails/PolicyDetailsEndorsementsModel'
import PolicyDetailsPremiumModel from '../models/PolicyDetails/PolicyDetailsPremiumModel'
import { PolicyDetailsPrimaryModel } from '../models/PolicyDetails/PolicyDetailsPrimaryModel'
import { PolicyDetailsResponseModel } from '../models/PolicyDetails/PolicyDetailsResponseModel'
import { PolicyErrorResponseModel } from '../models/PolicyDetails/PolicyErrorResponseModel'
import { UpdateErrorsModel } from '../models/PolicyDetails/UpdateErrorsModel'
import UnitStatModel from '../models/UnitStatModel'
import {
  getPolicyCancellationsReinstatements,
  getPolicyDetailExposure,
  getPolicyEmployersLocations,
  getPolicyEndorsements,
  getPolicyErrors,
  getPolicyListByCoverageId,
  getPolicyPremiums,
  getPolicyPrimaryName,
  getPolicySummaryDetails,
  getPolicyUnitStats,
  updatePolicyErrors,
} from '../services/policyService'
import { CacheKeys } from './cacheKeys'
import { StaleTime } from './staleTime'

const useGetPolicyListByCoverageId = (coverageId: string) =>
  useQuery<Policy[]>({
    queryKey: [CacheKeys.PolicyListByCoverageId, coverageId],
    queryFn: () => getPolicyListByCoverageId(coverageId),
    staleTime: StaleTime.THIRTY_MINUTES,
    enabled: !!coverageId,
  })

const useGetPolicySummaryDetails = (policyId: string) =>
  useQuery<PolicyDetailsResponseModel>({
    queryKey: [CacheKeys.PolicySummaryDetails, policyId],
    queryFn: () => getPolicySummaryDetails(policyId),
    staleTime: StaleTime.THIRTY_MINUTES,
    enabled: !!policyId,
  })

const useGetPolicyPrimaryName = (policyId: string) =>
  useQuery<PolicyDetailsPrimaryModel>({
    queryKey: [CacheKeys.PolicyPrimaryName, policyId],
    queryFn: () => getPolicyPrimaryName(policyId),
    staleTime: StaleTime.THIRTY_MINUTES,
    enabled: false,
  })

const useGetPolicyPremiums = (policyId: string) =>
  useQuery<PolicyDetailsPremiumModel[]>({
    queryKey: [CacheKeys.PolicyPremiums, policyId],
    queryFn: () => getPolicyPremiums(policyId),
    staleTime: StaleTime.THIRTY_MINUTES,
    enabled: false,
  })

const useGetPolicyExposure = (policyId: string) =>
  useQuery<ExposureModel>({
    queryKey: [CacheKeys.PolicyExposure, policyId],
    queryFn: () => getPolicyDetailExposure(policyId),
    staleTime: StaleTime.THIRTY_MINUTES,
    enabled: false,
  })

const useGetPolicyEmployersLocations = (policyId: string) =>
  useQuery<PolicyEmployersLocationsModel[]>({
    queryKey: [CacheKeys.PolicyEmployersLocations, policyId],
    queryFn: () => getPolicyEmployersLocations(policyId),
    staleTime: StaleTime.THIRTY_MINUTES,
    enabled: false,
  })

const useGetPolicyCancellationsReinstatements = (policyId: string) =>
  useQuery<PolicyDetailsCancellationsReintstatementsModel[]>({
    queryKey: [CacheKeys.PolicyCancellationsReinstatements, policyId],
    queryFn: () => getPolicyCancellationsReinstatements(policyId),
    staleTime: StaleTime.THIRTY_MINUTES,
    enabled: false,
  })

const useGetPolicyEndorsements = (policyId: string) =>
  useQuery<PolicyDetailsEndorsementsModel[]>({
    queryKey: [CacheKeys.PolicyEndorsements, policyId],
    queryFn: () => getPolicyEndorsements(policyId),
    staleTime: StaleTime.THIRTY_MINUTES,
    enabled: false,
  })

const useGetPolicyUnitStats = (policyId: string) =>
  useQuery<UnitStatModel[]>({
    queryKey: [CacheKeys.PolicyUnitStats, policyId],
    queryFn: () => getPolicyUnitStats(policyId),
    staleTime: StaleTime.THIRTY_MINUTES,
    enabled: false,
  })

const useGetPolicyErrors = (policyId: string) =>
  useQuery<PolicyErrorResponseModel[]>({
    queryKey: [CacheKeys.PolicyErrors, policyId],
    queryFn: () => getPolicyErrors(policyId),
    staleTime: StaleTime.THIRTY_MINUTES,
    enabled: false,
  })

// ====== MUTATIONS ====== //

const useUpdatePolicyErrors = (policyId: string, queryClient: QueryClient) =>
  useMutation({
    mutationFn: (request: UpdateErrorsModel) => updatePolicyErrors(policyId, request),
    onSuccess: () => {
      queryClient.fetchQuery({
        queryKey: [CacheKeys.PolicyErrors, policyId],
        queryFn: () => getPolicyErrors(policyId),
      })
      queryClient.invalidateQueries({ queryKey: [CacheKeys.PolicySummaryDetails, policyId] })
      queryClient.invalidateQueries({ queryKey: [CacheKeys.PolicyErrors, policyId] })
    },
  })

export {
  useGetPolicyErrors,
  useGetPolicyUnitStats,
  useGetPolicyEndorsements,
  useGetPolicyCancellationsReinstatements,
  useGetPolicyEmployersLocations,
  useGetPolicyExposure,
  useGetPolicyPremiums,
  useGetPolicyPrimaryName,
  useGetPolicySummaryDetails,
  useGetPolicyListByCoverageId,
  useUpdatePolicyErrors,
}
