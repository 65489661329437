export const Endpoints = {
  PolicySearchByCoverageId: '/api/coverages',
  ComboId: '/api/coverages',
  Combos: '/api/combos',
  Policy: '/api/policies',
  UnitStat: '/api/unitstats',
  Ratings: '/api/ratings',
  Ancestors: '/api/ancestors',
  Claims: '/api/claims',
  Coverages: '/api/coverages',
  WorkQueue: '/api/workQueue',
  Attachments: '/api/attachments',
}
