import { Box, Grid, Link, Typography } from '@mui/material'
import { FieldGroup, PhoenixBaseCard } from 'componix'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useGetCoverageDataByComboId } from '../../cache/coverageCache'
import ControllingShield from '../../media/ControllingShield'

interface RelateCoverageIDsCardProps {
  comboId: string | undefined
  coverageId: string | undefined
}

export const RelatedCoverageIDsCard = ({ comboId, coverageId }: RelateCoverageIDsCardProps) => {
  const navigate = useNavigate()
  const { data: coverageData, isPending: isCoverageDataLoading } = useGetCoverageDataByComboId(
    comboId as string,
    coverageId
  )

  const handleCoverageIDClick = (value: string | null) => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
    navigate(`/coverages/${value}`)
  }

  const relatedCoveragesKebabMenuItems = [{ label: 'View All Coverages', to: `/Combos/${comboId}` }]

  return (
    <PhoenixBaseCard
      cardTitle={'Related Coverage IDs'}
      isLoading={isCoverageDataLoading}
      contentPadded={true}
      kebabable
      kebabMenuItems={relatedCoveragesKebabMenuItems}
    >
      <Box maxHeight={150} padding="8px" sx={{ overflowY: 'auto' }}>
        {coverageData?.related?.length === 0 ? (
          <Typography variant="body1" title="NoRelatedContent">
            No other Coverage IDs exist.
          </Typography>
        ) : (
          coverageData?.related?.map((row) => (
            <Grid container key={row.guid}>
              <Grid item xs={6}>
                <FieldGroup
                  label={'Coverage ID'}
                  value={
                    <Link
                      id={'link'}
                      underline="hover"
                      component="button"
                      onClick={(event) => handleCoverageIDClick(event.currentTarget.textContent)}
                      sx={{ padding: 0, textAlign: 'left' }}
                    >
                      <Typography variant="body1" sx={{ fontWeight: '500' }}>
                        {
                          <span>
                            {row.controlling && <ControllingShield />}
                            <span style={{ verticalAlign: 'top' }}>{row.id}</span>
                          </span>
                        }
                      </Typography>
                    </Link>
                  }
                />
              </Grid>
              <Grid item xs={6} zeroMinWidth>
                <FieldGroup label={'Name'} value={row.name} multiLineWrap={true} />
              </Grid>
            </Grid>
          ))
        )}
      </Box>
    </PhoenixBaseCard>
  )
}

export default RelatedCoverageIDsCard
