import { Grid } from '@mui/material'
import { FieldGroup, PhoenixBaseCard, TableWithSubrows } from 'componix'
import { useGetUnitStatLossRecords } from '../../cache/unitStatCache'
import useSetAlertDetails from '../../hooks/workQueue/useSetAlertDetails'
import { formatMoney } from '../../utils/moneyFormatter'
import { UnitStatLossRecordsTableColumns } from './UnitStatLossRecordsTable/UnitStatLossRecordsTableColumns'

interface LossRecordsProps {
  uuid: string
}

export const LossRecordsSplit = ({ uuid }: LossRecordsProps) => {
  const {
    data: lossRecordsData,
    isLoading: isLossRecordsLoading,
    isError: isLossRecordsError,
    refetch: refetchLossRecords,
  } = useGetUnitStatLossRecords(uuid)

  useSetAlertDetails([isLossRecordsError])

  return (
    <div style={{ marginTop: '8px' }}>
      <PhoenixBaseCard
        collapsible={true}
        onExpand={refetchLossRecords}
        cardTitle="Loss Records"
        isLoading={isLossRecordsLoading}
      >
        <TableWithSubrows columns={UnitStatLossRecordsTableColumns} data={lossRecordsData?.records ?? []} searchable />
        <PhoenixBaseCard collapsible={false} variantType="Secondary" cardTitle={'Reported Loss Records Total'}>
          <Grid
            paddingLeft={'8px'}
            paddingTop={'8px'}
            flexDirection={'row'}
            container
            spacing={1}
            marginBottom={'16px'}
          >
            <Grid item sx={{ marginLeft: '16px', marginTop: '16px' }} xs={1.97}>
              <FieldGroup label={'CLAIMS'} value={lossRecordsData?.claimsTotal} />
            </Grid>
            <Grid item sx={{ marginTop: '16px' }} xs={1.97}>
              <FieldGroup label={'INCURRED INDEMNITY'} value={formatMoney(lossRecordsData?.incurredIndemnityTotal)} />
            </Grid>
            <Grid item sx={{ marginTop: '16px' }} xs={1.97}>
              <FieldGroup label={'INCURRED MEDICAL'} value={formatMoney(lossRecordsData?.incurredMedicalTotal)} />
            </Grid>
            <Grid item sx={{ marginTop: '16px' }} xs={1.97}>
              <FieldGroup label={'PAID INDEMNITY'} value={formatMoney(lossRecordsData?.paidIndemnityTotal)} />
            </Grid>
            <Grid item sx={{ marginTop: '16px' }} xs={1.97}>
              <FieldGroup label={'PAID MEDICAL'} value={formatMoney(lossRecordsData?.paidMedicalTotal)} />
            </Grid>
            <Grid item sx={{ marginTop: '16px' }} xs={1.9}>
              <FieldGroup label={'ALAE PAID'} value={formatMoney(lossRecordsData?.alaePaidTotal)} />
            </Grid>
          </Grid>
        </PhoenixBaseCard>
      </PhoenixBaseCard>
    </div>
  )
}

export default LossRecordsSplit
