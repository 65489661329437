import React, { createContext, useContext, useState } from 'react'
import { searchByComboID } from '../services/comboService'
import { getComboDataByCoverageId, searchByFEIN, searchByName } from '../services/coverageService'
import { getPolicyListByCoverageId, searchPolicyByPolicyNumber } from '../services/policyService'
import { SearchCategories } from '../utils/searchCategories.enum'

interface SearchContextProps {
  searchValue: string
  setSearchValue: React.Dispatch<React.SetStateAction<string>>
  searchCategory: SearchCategories
  setSearchCategory: React.Dispatch<React.SetStateAction<SearchCategories>>
  searchError: boolean
  setSearchError: React.Dispatch<React.SetStateAction<boolean>>
  isSearching: boolean
  setIsSearching: React.Dispatch<React.SetStateAction<boolean>>
  // eslint-disable-next-line
  searchResults: any | null
  // eslint-disable-next-line
  setSearchResults: React.Dispatch<React.SetStateAction<any>>

  tooManyResults: boolean
  setTooManyResults: React.Dispatch<React.SetStateAction<boolean>>
  startSearch: (searchValue: string, searchType: SearchCategories) => void
  resetSearch: () => void
}

export const SearchContext = createContext<SearchContextProps | undefined>(undefined)

const SearchProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [searchValue, setSearchValue] = useState('')
  const [searchCategory, setSearchCategory] = useState<SearchCategories>(SearchCategories.undefined)
  const [searchError, setSearchError] = useState(false)
  const [isSearching, setIsSearching] = useState(false)
  const [tooManyResults, setTooManyResults] = useState(false)
  // eslint-disable-next-line
  const [searchResults, setSearchResults] = useState<any>(null)
  const limit = 100
  const resetSearch = () => {
    setSearchValue('')
    setSearchCategory(SearchCategories.undefined)
    setSearchError(false)
    setIsSearching(false)
    setTooManyResults(false)
    setSearchResults(null)
  }

  //Start Searching and set search results based on search category
  const startSearch = (value: string, searchCategory: SearchCategories) => {
    setIsSearching(true)
    setTooManyResults(false)
    setSearchResults(null)
    switch (searchCategory) {
      case SearchCategories.Coverage:
        getComboDataByCoverageId(value)
          .then((comboResponse) => {
            if (comboResponse?.coverageGuid != null) {
              getPolicyListByCoverageId(comboResponse.coverageGuid).then((response) => {
                setSearchResults(response)
                setIsSearching(false)
              })
            } else {
              setSearchResults([])
              setIsSearching(false)
            }
          })
          .catch(() => {
            setSearchError(true)
            setIsSearching(false)
          })
        break
      case SearchCategories.Policy:
        searchPolicyByPolicyNumber(value)
          .then((response) => {
            setSearchResults(response)
            setIsSearching(false)
          })
          .catch(() => {
            setSearchError(true)
            setIsSearching(false)
          })
        break
      case SearchCategories.ComboRatings:
        searchByComboID(value)
          .then((response) => {
            setSearchResults(response)
            setIsSearching(false)
          })
          .catch(() => {
            setSearchError(true)
            setIsSearching(false)
          })
        break
      case SearchCategories.FEIN:
        searchByFEIN(value)
          .then((response) => {
            if (response.count > limit) {
              setTooManyResults(true)
            }
            setSearchResults(response.data)
            setIsSearching(false)
          })
          .catch(() => {
            setSearchError(true)
            setIsSearching(false)
          })
        break
      case SearchCategories.Name:
        searchByName(value)
          .then((response) => {
            if (response.count > limit) {
              setTooManyResults(true)
            }
            setSearchResults(response.data)
            setIsSearching(false)
          })
          .catch(() => {
            setSearchError(true)
            setIsSearching(false)
          })
        break
      default:
        break
    }
  }

  return (
    <SearchContext.Provider
      value={{
        searchValue,
        setSearchValue,
        searchCategory,
        setSearchCategory,
        searchError,
        setSearchError,
        isSearching,
        setIsSearching,
        searchResults,
        setSearchResults,
        tooManyResults,
        setTooManyResults,
        startSearch,
        resetSearch,
      }}
    >
      {children}
    </SearchContext.Provider>
  )
}

const useSearch = (): SearchContextProps => {
  const context = useContext(SearchContext)

  if (!context) {
    throw new Error('useSearch must be used within a SearchBarProvider')
  }

  return context
}

export { SearchProvider, useSearch }
