import { CalendarToday, CreditCard, Star } from '@mui/icons-material'
import { Box, Grid, Paper, Switch, Typography } from '@mui/material'
import {
  BreadcrumbBar,
  DataFieldBlankValue,
  FieldGroup,
  PhoenixBaseCard,
  PhoenixBaseTable,
  StatusBadge,
} from 'componix'
import { UUID } from 'crypto'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import ContributingExperienceColumns from '../../src/components/RatingDetails/ContributingExperienceColumns'
import { useGetAncestors } from '../cache/ancestorCache'
import { useGetRatingDetails } from '../cache/ratingCache'
import PrimaryCoverageIdentifier from '../components/GroupIdentifier'
import LayoutWithSideRail from '../components/LayoutWithSideRail'
import RatingAdditionalInformationBanner from '../components/RatingDetails/RatingAdditionalInformationBanner'
import RatingDetailsBanner from '../components/RatingDetails/RatingDetailsBanner'
import RelateCoverageIDsCard from '../components/RelatedCoverageIDs'
import { useNavbar } from '../contexts/NavbarContext'
import useSetAlertDetails from '../hooks/workQueue/useSetAlertDetails'
import { ContributingExperienceModel } from '../models/ContributingExperienceModel'
import { HierarchyTypes } from '../utils/hierarchy.enum'
import { formatMoney } from '../utils/moneyFormatter'

export const RatingDetails = () => {
  const [displayExcluded, setDisplayExcluded] = useState(false)
  const [filteredList, setFilteredList] = useState<ContributingExperienceModel[]>([])
  const [unfilteredList, setUnfilteredList] = useState<ContributingExperienceModel[]>([])
  const { ratingId } = useParams()
  const { marginLeft } = useNavbar()

  const { data: ancestorData } = useGetAncestors(ratingId as UUID, HierarchyTypes.RATING)

  const {
    data: ratingDetailsData,
    isPending: isRatingDetailsLoading,
    isError: isRatingDetailsError,
  } = useGetRatingDetails(ratingId as UUID)

  useSetAlertDetails([isRatingDetailsError])

  useEffect(() => {
    if (ratingDetailsData && ratingDetailsData?.contributingExperience?.length > 0 && !isRatingDetailsLoading) {
      filteringList(ratingDetailsData.contributingExperience)
    }
  }, [ratingDetailsData, isRatingDetailsLoading])

  /*istanbul ignore next */
  const filteringList = async (list: ContributingExperienceModel[]) => {
    setUnfilteredList(list)
    setFilteredList(list.filter((key) => key.accepted))
  }

  return (
    <div
      style={{
        transition: 'margin-left 0.2s',
        marginLeft: marginLeft,
        marginRight: '24px',
        marginTop: '80px',
      }}
    >
      <BreadcrumbBar
        crumbs={[
          {
            label: `Combo ${ancestorData?.combo?.identifier}`,
            to: `/combos/${ancestorData?.combo?.guid}`,
          },
          {
            label: `Rating - ${isRatingDetailsLoading ? undefined : ratingDetailsData?.ratingDate}`,
            to: `/ratings/${ratingId}`,
          },
        ]}
      />
      <LayoutWithSideRail
        mainContent={
          <>
            <PhoenixBaseCard cardTitle="Rating Details" contentPadded={true} isLoading={isRatingDetailsLoading}>
              <Box flex={1}>
                <RatingDetailsBanner
                  ratingStatus={ratingDetailsData?.status}
                  hasBeenSuperseded={ratingDetailsData?.hasBeenSuperseded}
                />
                <Box p={2}>
                  <Grid container spacing={1}>
                    <Grid item xs={2}>
                      <FieldGroup
                        label="RATING DATE"
                        value={ratingDetailsData?.ratingDate}
                        icon={<CalendarToday sx={{ paddingRight: '4px' }} />}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <FieldGroup label="REVISION NUMBER" value={ratingDetailsData?.revision} />
                    </Grid>
                    <Grid item xs={2}>
                      <FieldGroup label={'MOD FACTOR'} value={ratingDetailsData?.modFactor} icon={<Star />} />
                    </Grid>
                    <Grid item xs={2}>
                      <FieldGroup label={'RESULT'} value={ratingDetailsData?.result} />
                    </Grid>
                    <Grid item xs={2}>
                      <FieldGroup
                        label="STATUS"
                        value={
                          <Paper sx={{ marginTop: '4px' }} elevation={0}>
                            <StatusBadge description={ratingDetailsData?.status} />
                          </Paper>
                        }
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <FieldGroup
                        label="STATUS DATE"
                        value={ratingDetailsData?.statusDate}
                        icon={<CalendarToday sx={{ paddingRight: '4px' }} />}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <FieldGroup
                        label={'EXPERIENCE WINDOW'}
                        value={
                          ratingDetailsData?.experienceEffectiveDateMin &&
                          ratingDetailsData?.experienceExpirationDateMax ? (
                            `${ratingDetailsData.experienceEffectiveDateMin} - ${ratingDetailsData.experienceExpirationDateMax}`
                          ) : (
                            <DataFieldBlankValue />
                          )
                        }
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <FieldGroup
                        label={'TOTAL PREMIUM'}
                        icon={<CreditCard sx={{ paddingRight: '4px' }} />}
                        value={formatMoney(ratingDetailsData?.totalPremium)}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <FieldGroup
                        label={'PRIMARY/EXCESS SPLIT POINTS'}
                        value={formatMoney(ratingDetailsData?.splitPoints)}
                      />
                    </Grid>
                  </Grid>
                </Box>
                <RatingAdditionalInformationBanner
                  additionalInformation={ratingDetailsData?.additionalInformation ?? []}
                />
              </Box>
              <PhoenixBaseCard variantType="Secondary" cardTitle={'Contributing Experience'}>
                <Box p={2} flex={1}>
                  <Grid container spacing={2}>
                    <Grid item xs={4}>
                      <FieldGroup
                        label="EXPERIENCE PERIOD BEGIN DATE"
                        value={ratingDetailsData?.experienceEffectiveDateMin}
                        icon={<CalendarToday sx={{ paddingRight: '4px' }} />}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <FieldGroup
                        label="EXPERIENCE PERIOD END DATE"
                        value={ratingDetailsData?.experienceExpirationDateMax}
                        icon={<CalendarToday sx={{ paddingRight: '4px' }} />}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <FieldGroup label="MONTHS OF EXPERIENCE" value={ratingDetailsData?.experienceMonths} />
                    </Grid>
                  </Grid>
                </Box>
                <Grid container alignItems={'center'} sx={{ backgroundColor: '#ECEFF1' }}>
                  <Grid item>
                    <Switch
                      data-testid="display-excluded"
                      disabled={unfilteredList.length === filteredList.length}
                      checked={displayExcluded}
                      onClick={() => setDisplayExcluded(!displayExcluded)}
                    />
                  </Grid>
                  <Typography fontSize={12}>Display Excluded</Typography>
                </Grid>

                <PhoenixBaseTable
                  overrides={{
                    //eslint-disable-next-line
                    muiTableBodyRowProps: ({ row }: any) => ({
                      sx: {
                        '& > .MuiTableCell-root': {
                          color: row.getValue('accepted') ? 'normal' : '#A5A4A5',
                        },
                      },
                    }),
                  }}
                  showPagination={true}
                  showRowsPerPage={true}
                  columns={ContributingExperienceColumns}
                  data={displayExcluded ? unfilteredList : filteredList}
                />
              </PhoenixBaseCard>
            </PhoenixBaseCard>
          </>
        }
        sideRailComponentArray={[
          <PrimaryCoverageIdentifier title="Combo Group" key={'cgh'} combo={ancestorData?.combo} />,
          <RelateCoverageIDsCard
            comboId={ancestorData?.combo?.guid}
            coverageId={ancestorData?.coverage?.guid}
            key="rcic"
          />,
        ]}
      ></LayoutWithSideRail>
    </div>
  )
}
