import { Box, Link } from '@mui/material'
import { KebabNavigationMenu, PhoenixBaseCard, PhoenixBaseTable, StatusBadge } from 'componix'
import { MRT_ColumnDef } from 'material-react-table'
import { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { useGetPolicyUnitStats } from '../../cache/policyCache'
import useSetAlertDetails from '../../hooks/workQueue/useSetAlertDetails'
import UnitStatModel from '../../models/UnitStatModel'

const UnitStatReportsTable = ({ policyID }: { policyID: string }) => {
  const navigate = useNavigate()
  const { data, isLoading, isError, refetch } = useGetPolicyUnitStats(policyID)
  useSetAlertDetails([isError])

  // ============================= START COLUMN DEF=============================
  /* eslint react/prop-types: 0 */ /* istanbul ignore next */
  const columns = useMemo<MRT_ColumnDef<UnitStatModel>[]>(
    () => [
      {
        accessorKey: 'receivedDate',
        header: 'RECEIVED DATE',
        Cell: ({ cell, row }) => (
          <>
            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
              <Box paddingRight={'8px'}>
                <KebabNavigationMenu
                  id={`UnitListNavButton-${row.index}`}
                  menuItems={[
                    {
                      label: 'View Unit Stat Details',
                      id: `unitStatNavMenuItem-1`,
                      to: `/unitstats/${row.original.unitStatId}`,
                    },
                  ]}
                />
              </Box>
              {/* eslint-disable-next-line */}
              <>{cell.getValue()}</>
            </Box>
          </>
        ),
        size: 10,
      },
      {
        accessorKey: 'status',
        header: 'STATUS',
        Cell: ({ cell }) => <StatusBadge description={cell.getValue<string>() ?? '----'} />,
        size: 10,
      },

      {
        accessorKey: 'reportLevel',
        header: 'REPORT LEVEL',
        Cell: ({ cell, row }) => (
          <Link
            id={`ReportLink-${row.index}`}
            onClick={() => {
              navigate(`/unitstats/${row.original.unitStatId}`)
            }}
            sx={{
              textDecoration: 'none',
              '&:hover': {
                textDecoration: 'underline',
                cursor: 'pointer',
              },
            }}
          >
            {/* eslint-disable-next-line */}
            <>{cell.getValue() ?? '----'}</>
          </Link>
        ),
      },
      {
        accessorKey: 'correctionSeq',
        header: 'CORRECTION SEQ',
        Cell: ({ cell }) => <>{cell.getValue() ?? '----'}</>,
        size: 10,
      },

      {
        accessorKey: 'correctionType',
        header: 'CORRECTION TYPE',
        Cell: ({ cell }) => <>{cell.getValue() ?? '----'}</>,
        size: 10,
      },
    ],
    // eslint-disable-next-line
    []
  )
  // ============================= END COLUMN DEF ==============================

  return (
    <PhoenixBaseCard cardTitle={'Unit Stat Reports'} isLoading={isLoading} collapsible onExpand={refetch}>
      <PhoenixBaseTable columns={columns} data={data ?? []} isLoading={isLoading} />
    </PhoenixBaseCard>
  )
}
export default UnitStatReportsTable
