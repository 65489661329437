import { WorkTypes } from './workTypes.enum'

export const PT1WorkTypes = [WorkTypes.UnmatchedTransaction, WorkTypes.CarrierErrorPolicy, WorkTypes.CarrierErrorUSR]

export const isWorkItemTypePT1 = (workItemType?: string): boolean => {
  if (!workItemType) {
    return false
  }
  return PT1WorkTypes.includes(workItemType as WorkTypes)
}
