import { useQuery } from '@tanstack/react-query'
import { ClaimDetailsResponse } from '../models/ClaimDetails/ClaimDetailsResponse'
import { getClaimDetails } from '../services/claimService'
import { CacheKeys } from './cacheKeys'
import { StaleTime } from './staleTime'

const useGetClaimDetails = (unitStatLossId: string | undefined, unitStatId: string | undefined) => {
  return useQuery<ClaimDetailsResponse>({
    queryKey: [CacheKeys.ClaimDetails, unitStatLossId, unitStatId],
    // casting as string because function is otherwise blocked by enabled if undefined or null
    queryFn: () => getClaimDetails(unitStatLossId as string, unitStatId as string),
    enabled: !!unitStatLossId && !!unitStatId,
    staleTime: StaleTime.THIRTY_MINUTES,
  })
}
export { useGetClaimDetails }
