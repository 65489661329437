import { Box, CircularProgress } from '@mui/material'
import { NoResultsFound, PhoenixBaseCard, PhoenixBaseTable, PhoenixLink, StatusBadge } from 'componix'
import { MRT_ColumnDef } from 'material-react-table'
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { AlertSeverity } from '../components/AlertNotification/AlertNotification'
import SearchBar from '../components/SearchBar/SearchBar'
import { useAlertContext } from '../contexts/AlertContext'
import { useNavbar } from '../contexts/NavbarContext'
import { useSearch } from '../contexts/SearchContext'
import { SearchByPolicyNumberResponseModel } from '../models/SearchByPolicyNumberResponseModel'

/* eslint-disable  @typescript-eslint/no-explicit-any */
import { SearchResponse, SearchResponseData } from '../models/SearchResponse'
import { SearchCategories } from '../utils/searchCategories.enum'

//Consider updating the policy search response to include FEIN and PolicyNameType
const policySearchResultsColumnDef: MRT_ColumnDef<SearchByPolicyNumberResponseModel>[] = [
  /* eslint react/prop-types: 0 */
  {
    accessorKey: 'comboId',
    header: 'Combo Id',
    Cell: ({ cell, row }) => {
      return <PhoenixLink to={`/combos/${row.original.comboGuid}`} value={cell.getValue<string>()} />
    },
  },
  {
    accessorKey: 'coverageId',
    header: 'Coverage Id',
    Cell: ({ cell }) => {
      return <PhoenixLink to={`/coverages/${cell.getValue<string>()}`} value={cell.getValue<string>()} />
    },
  },
  { accessorKey: 'carrierId', header: 'Carrier Id' },
  {
    accessorKey: 'policyNumber',
    header: 'Policy Number',
    Cell: ({ cell, row }) => {
      return <PhoenixLink to={`/policies/${row.original.policyGuid}`} value={cell.getValue<string>()} />
    },
  },
  { accessorKey: 'effectiveDate', header: 'Policy Effective Date' },
  { accessorKey: 'expirationDate', header: 'Policy Expiration Date' },
  { accessorKey: 'primaryName', header: 'Primary Name' },
  {
    accessorKey: 'status',
    header: 'Status',
    Cell: ({ cell }) => <StatusBadge description={cell.getValue<string>()} />,
  },
]

const searchResultsColumnDef: MRT_ColumnDef<SearchResponseData>[] = [
  /* istanbul ignore next */
  {
    accessorKey: 'comboId',
    header: 'Combo Id',
    Cell: ({ cell, row }) => {
      return <PhoenixLink to={`/combos/${row.original.comboGuid}`} value={cell.getValue<string>()} />
    },
  },
  {
    accessorKey: 'coverageId',
    header: 'Coverage Id',
    Cell: ({ cell }) => {
      return <PhoenixLink to={`/coverages/${cell.getValue<string>()}`} value={cell.getValue<string>()} />
    },
  },
  { accessorKey: 'carrierId', header: 'Carrier Id' },
  {
    accessorKey: 'policyNumber',
    header: 'Policy Number',
    Cell: ({ cell, row }) => {
      return <PhoenixLink to={`/policies/${row.original.policyGuid}`} value={cell.getValue<string>()} />
    },
  },
  { accessorKey: 'policyNameType', header: 'Name Type' },
  { accessorKey: 'employerName', header: 'Primary Name' },
  {
    accessorKey: 'fein',
    header: 'FEIN',
  },
  { accessorKey: 'effectiveDate', header: 'Policy Effective Date' },
  {
    accessorKey: 'status',
    header: 'Status',
    Cell: ({ cell }) => <StatusBadge description={cell.getValue<string>()} />,
  },
]
export const Search: React.FC = () => {
  const { marginLeft } = useNavbar()
  const { searchResults, searchCategory, isSearching, searchValue, resetSearch, searchError, tooManyResults } =
    useSearch()
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  const { setDetails: setAlertDetails } = useAlertContext()
  const [columnDef, setColumnDef] = React.useState<MRT_ColumnDef<any>[]>([])
  const noResultMessage = 'No records to display.'

  const navigate = useNavigate()

  useEffect(() => {
    if (searchError) {
      setAlertDetails({
        message: 'An error occurred while searching. Please try again.',
        severity: AlertSeverity.ERROR,
      })
    }
  }, [searchError, setAlertDetails])

  useEffect(() => {
    switch (searchCategory) {
      case SearchCategories.Coverage:
        coverageSearchResults()
        break
      case SearchCategories.Policy:
        policySearchResults()
        break
      case SearchCategories.ComboRatings:
        comboSearchResults()
        break
      case SearchCategories.FEIN:
        feinSearchResults()
        break
      case SearchCategories.Name:
        nameSearchResults()
        break
      default:
        break
    }
    // eslint-disable-next-line
  }, [searchResults, searchCategory])

  const coverageSearchResults = () => {
    let coverageId
    if (searchResults?.length > 0) {
      coverageId = searchValue
      resetSearch()
      navigate(`/coverages/${coverageId}`)
    }
  }

  const policySearchResults = () => {
    if (searchResults?.length > 0) {
      //eslint-disable-next-line
      setColumnDef(policySearchResultsColumnDef as MRT_ColumnDef<any>[])
    }
  }

  const comboSearchResults = () => {
    let comboGuid
    //check for empty object
    if (searchResults != null && Object.keys(searchResults).length != 0) {
      comboGuid = searchResults.comboGuid
      resetSearch()
      navigate(`/combos/${comboGuid}`)
    }
  }
  const feinSearchResults = () => {
    let coverageId
    if (searchResults?.length == 1) {
      coverageId = searchResults[0].coverageId
      resetSearch()
      navigate(`/coverages/${coverageId}`)
    }
    if (searchResults?.length > 1) {
      setColumnDef(searchResultsColumnDef as MRT_ColumnDef<any>[])
    }
  }
  const nameSearchResults = () => {
    if (searchResults?.length > 0) {
      setColumnDef(searchResultsColumnDef as MRT_ColumnDef<any>[])
    }
  }

  return (
    <Box style={{ transition: 'margin-left 0.2s', marginLeft: marginLeft, marginRight: '24px', marginTop: '80px' }}>
      <SearchBar />
      <Box marginTop={'24px'} marginBottom={'24px'}>
        {isSearching && (
          <CircularProgress
            size={60}
            sx={{
              display: 'block',
              margin: 'auto',
            }}
          />
        )}

        {searchResults && !isSearching && (
          <PhoenixBaseCard cardTitle="Search Results">
            {tooManyResults && <NoResultsFound message="Please refine your search criteria and search again." />}
            {!tooManyResults && (searchResults.length === 0 || Object.keys(searchResults).length === 0) && (
              <NoResultsFound message={noResultMessage} />
            )}

            {searchResults.length > 0 && (
              <PhoenixBaseTable
                overrides={{
                  muiTopToolbarProps: { sx: { backgroundColor: '#ECEFF1' } },
                  enableGlobalFilter: true,
                  enableFilters: true,
                  enableTopToolbar: true,
                }}
                columns={columnDef}
                data={searchResults}
              />
            )}
          </PhoenixBaseCard>
        )}
      </Box>
    </Box>
  )
}

export default Search
