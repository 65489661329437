import { UUID } from 'crypto'
import React from 'react'
import { useParams } from 'react-router-dom'
import { useGetPolicyPrimaryName } from '../../cache/policyCache'
import useSetAlertDetails from '../../hooks/workQueue/useSetAlertDetails'
import { PolicyDetailsResponseModel } from '../../models/PolicyDetails/PolicyDetailsResponseModel'
import PolicyDetailsInformationCard from './PolicyDetailsInformationCard'
import { PolicyDetailsPrimaryNameCard } from './PolicyDetailsPrimaryNameCard'
import { PolicyDetailsProducerInformationCard } from './PolicyDetailsProducerInformationCard'
import PolicyDetailsSummaryCard from './PolicyDetailsSummaryCard'

interface PolicyDetailsContentProps {
  policySummaryData: PolicyDetailsResponseModel | undefined
  isPolicySummaryLoading: boolean
  isPolicySummaryError: boolean
}

export const PolicyDetailsContent = ({
  policySummaryData,
  isPolicySummaryLoading,
  isPolicySummaryError,
}: PolicyDetailsContentProps) => {
  const { policyId } = useParams()

  const {
    data: primaryNameData,
    isLoading: isPrimaryNameLoading,
    isError: isPrimaryNameError,
    refetch: refetchPrimaryName,
  } = useGetPolicyPrimaryName(policyId as UUID)

  useSetAlertDetails([isPolicySummaryError, isPrimaryNameError])

  return (
    <>
      <PolicyDetailsSummaryCard data={policySummaryData?.summary} isLoading={isPolicySummaryLoading} />
      <PolicyDetailsInformationCard isLoading={isPolicySummaryLoading} data={policySummaryData?.information} />
      <PolicyDetailsPrimaryNameCard
        isLoading={isPrimaryNameLoading}
        data={primaryNameData}
        handleFirstOpen={refetchPrimaryName}
      />
      <PolicyDetailsProducerInformationCard isLoading={isPolicySummaryLoading} data={policySummaryData?.producer} />
    </>
  )
}
export default PolicyDetailsContent
