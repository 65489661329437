import { PhoenixBaseCard, PhoenixBaseTable, PhoenixLink } from 'componix'
import { MRT_ColumnDef } from 'material-react-table'
import { CombinedCoverageHistoryModel } from '../../models/CombinedCoverageHistory'

const columns: MRT_ColumnDef<CombinedCoverageHistoryModel>[] = [
  {
    accessorKey: 'coverageId',
    header: 'COVERAGE ID',
    // eslint-disable-next-line react/prop-types
    Cell: ({ cell }) => <PhoenixLink to={`/coverages/${cell.getValue<string>()}`} value={cell.getValue<string>()} />,
  },
  { accessorKey: 'primaryName', minSize: 150, header: 'CURRENT PRIMARY NAME' },
  { accessorKey: 'includeDate', header: 'INCLUDE DATE' },
  { accessorKey: 'excludeDate', header: 'EXCLUDE DATE' },
  { accessorKey: 'controllingStartDate', minSize: 120, header: 'CONTROLLING START DATE' },
  { accessorKey: 'controllingEndDate', minSize: 120, header: 'CONTROLLING END DATE' },
]

interface CombinedCoverageHistoryProps {
  data: CombinedCoverageHistoryModel[] | undefined
  isLoading: boolean
}

const CombinedCoverageHistory = ({ data, isLoading }: CombinedCoverageHistoryProps) => {
  return (
    <div style={{ marginBottom: '8px' }}>
      <PhoenixBaseCard cardTitle={'Combined Coverage History'}>
        <PhoenixBaseTable columns={columns} data={data ?? []} isLoading={isLoading} />
      </PhoenixBaseCard>
    </div>
  )
}

export default CombinedCoverageHistory
