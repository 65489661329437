import { useQuery } from '@tanstack/react-query'
import { UUID } from 'crypto'
import { AncestorResponse } from '../models/AncestorResponse'
import { getAncestors } from '../services/ancestorsService'
import { HierarchyTypes } from '../utils/hierarchy.enum'
import { CacheKeys } from './cacheKeys'
import { StaleTime } from './staleTime'

const useGetAncestors = (id: UUID, type: HierarchyTypes) =>
  useQuery<AncestorResponse>({
    queryKey: [CacheKeys.Ancestors, id, type],
    queryFn: () => getAncestors(id, type),
    staleTime: StaleTime.ONE_MINUTE,
    enabled: !!id,
  })

export { useGetAncestors }
