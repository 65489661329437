import { CalendarToday, Star } from '@mui/icons-material'
import { Grid, Paper } from '@mui/material'
import { FieldGroup, PhoenixBaseCard, PhoenixLink, StatusBadge } from 'componix'
import { useGetRatingInfoByComboId } from '../../cache/ratingCache'
import useSetAlertDetails from '../../hooks/workQueue/useSetAlertDetails'
import { Ancestor } from '../../models/AncestorResponse'

interface ExperienceRatingProps {
  combo: Ancestor | null | undefined
}

export const ExperienceRating = ({ combo }: ExperienceRatingProps) => {
  const { data: ratingInfo, isPending: isLoading, isError } = useGetRatingInfoByComboId(combo?.identifier as string)
  useSetAlertDetails([isError])

  const experienceRatingKebabMenuItems = [{ label: 'View Rating History', to: `/Combos/${combo?.guid}` }]

  return (
    <PhoenixBaseCard
      cardTitle={'Experience Rating'}
      contentPadded={true}
      kebabable
      kebabMenuItems={experienceRatingKebabMenuItems}
    >
      <Grid container padding="8px">
        <FieldGroup
          label={'Mod Factor'}
          value={
            <PhoenixLink
              to={`/ratings/${ratingInfo?.ratingId}`}
              value={ratingInfo?.modFactor === null ? '1.0' : String(ratingInfo?.modFactor)}
            />
          }
          icon={<Star />}
          isLoading={isLoading}
        />
        <FieldGroup
          label={'Rating Effective Date'}
          value={ratingInfo?.ratingEffectiveDate}
          icon={<CalendarToday />}
          isLoading={isLoading}
        />
        <Grid item xs={5}>
          <FieldGroup
            label={'Status'}
            value={
              <Paper elevation={0} sx={{ pr: 1 / 2 }}>
                <StatusBadge description={ratingInfo?.status} />
              </Paper>
            }
            isLoading={isLoading}
          />
        </Grid>
        <Grid item xs={7}>
          <FieldGroup label={'Result'} value={ratingInfo?.result} isLoading={isLoading} />
        </Grid>
        <Grid item xs={5}>
          <FieldGroup label={'Issue Date'} value={ratingInfo?.issueDate} isLoading={isLoading} />
        </Grid>
        <Grid item xs={7}>
          <FieldGroup label={'Revision Number'} value={ratingInfo?.revisionNumber} isLoading={isLoading} />
        </Grid>
      </Grid>
    </PhoenixBaseCard>
  )
}
