import { PhoenixBaseCard } from 'componix'
import { useGetPolicyEndorsements } from '../../cache/policyCache'
import useSetAlertDetails from '../../hooks/workQueue/useSetAlertDetails'
import PolicyEndorsementsTable from './PolicyEndorsementsTable'

interface PolicyEndorsementsProps {
  uuid: string
}

export const PolicyEndorsementsContent = ({ uuid }: PolicyEndorsementsProps) => {
  const { data, isLoading, isError, refetch } = useGetPolicyEndorsements(uuid)
  useSetAlertDetails([isError])

  return (
    <PhoenixBaseCard cardTitle={'Endorsements'} collapsible={true} onExpand={refetch} isLoading={isLoading}>
      <PolicyEndorsementsTable data={data ?? []} isLoading={isLoading} />
    </PhoenixBaseCard>
  )
}

export default PolicyEndorsementsContent
