export enum WorkTypes {
  UnmatchedTransaction = 'Unmatched Transaction',
  CarrierErrorUSR = 'Carrier Error Notification - USR',
  CarrierErrorPolicy = 'Carrier Error Notification - Policy',
  OwnershipApplication = 'Ownership Application',
  LetterOfAuthority = 'Letter of Authority',
  NameChange = 'Name Change',
  NameNotAllowed = 'Name Not Allowed',
  RatingDate = 'Rating Date - Dual Coverage',
  DocumentRequest = 'Document Request',
  HeldRating = 'Held Rating',
}
