import { useQuery } from '@tanstack/react-query'
import { RatingDetailsModel } from '../models/RatingDetailsModel'
import { RatingInfoModel } from '../models/RatingInfoModel'
import { getRatingDetails, getRatingInfoByComboId } from '../services/ratingService'
import { CacheKeys } from './cacheKeys'
import { StaleTime } from './staleTime'

const useGetRatingDetails = (ratingId: string) =>
  useQuery<RatingDetailsModel>({
    queryKey: [CacheKeys.RatingDetails, ratingId],
    queryFn: () => getRatingDetails(ratingId),
    staleTime: StaleTime.THIRTY_MINUTES,
    enabled: !!ratingId,
  })

const useGetRatingInfoByComboId = (comboId: string) =>
  useQuery<RatingInfoModel>({
    queryKey: [CacheKeys.RatingInfoByComboId, comboId],
    queryFn: () => getRatingInfoByComboId(String(comboId)),
    staleTime: StaleTime.THIRTY_MINUTES,
    enabled: !!comboId,
  })

export { useGetRatingInfoByComboId, useGetRatingDetails }
