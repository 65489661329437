import { LocationOn, Person } from '@mui/icons-material'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import InfoIcon from '@mui/icons-material/Info'
import WorkIcon from '@mui/icons-material/Work'
import LoadingButton from '@mui/lab/LoadingButton'
import { Box, Button, Divider, Grid, Stack, TextField, Typography } from '@mui/material'
import { FieldGroup, PhoenixBaseCard } from 'componix'
import { useEffect } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { useFindMatchingCombo, useFindMatchingCoverage } from '../../cache/workQueueCache'
import { addressFormatter } from '../../utils/addressFormatter'
import { MatchingCoverageResponse } from './MatchingCoverageResponse'
import { SuggestedMatchModel } from './SuggestedMatchModel'

interface IFormInput {
  coverageID: string
}

interface IFormComboInput {
  comboID: string
}

interface FindMatchFormsProps {
  match: SuggestedMatchModel | null
  setFoundMatch: (match: SuggestedMatchModel | null) => void
  isModalOpen: boolean
  doesPolicyHaveMatch: boolean
}

/*
    Ideally we won't have 2 forms in one modal like this
    But they have their own validation and separate endpoints
    So it made sense to separate them
*/

const FindMatchForms = ({ match, setFoundMatch, isModalOpen, doesPolicyHaveMatch }: FindMatchFormsProps) => {
  const {
    control: controlCoverage,
    handleSubmit: handleSubmitCoverage,
    register: registerCoverage,
    watch: watchCoverage,
    reset: resetCoverage,
    setError: setErrorCoverage,
  } = useForm({
    mode: 'all',
    defaultValues: {
      coverageID: '',
    },
  })

  const {
    control: controlCombo,
    handleSubmit: handleSubmitCombo,
    register: registerCombo,
    watch: watchCombo,
    reset: resetCombo,
    setError: setErrorCombo,
  } = useForm({
    mode: 'all',
    defaultValues: {
      comboID: '',
    },
  })

  const formCoverageId = watchCoverage('coverageID')
  const formComboId = watchCombo('comboID')

  const { refetch: fetchMatchingCoverage, isRefetching: isFetchingMatchingCoverage } =
    useFindMatchingCoverage(formCoverageId)

  const { refetch: fetchMatchingCombo, isRefetching: isFetchingMatchingCombo } = useFindMatchingCombo(formComboId)

  const mapResponseToMatch = (data: MatchingCoverageResponse | null): SuggestedMatchModel => ({
    suggestionID: '',
    fein: data?.fein ?? '',
    combo: {
      identifier: data?.comboId ?? 'New',
      guid: data?.comboGuid ?? '',
    },
    coverage: {
      identifier: data?.coverageId ?? 'New',
      guid: data?.coverageGuid ?? '',
    },
    employer: data?.primaryName ?? '',
    address: addressFormatter(data?.address) as string,
    open: true,
  })

  const onSubmitFindMatchingCoverage: SubmitHandler<IFormInput> = async () => {
    const result = await fetchMatchingCoverage()
    if (result.data) {
      const match = mapResponseToMatch(result.data)
      setFoundMatch(match)
    } else {
      setErrorCoverage('coverageID', { message: 'No records to display.' })
    }
  }

  const onSubmitFindMatchingCombo: SubmitHandler<IFormComboInput> = async (data) => {
    if (!data.comboID) {
      const match = mapResponseToMatch(null)
      setFoundMatch(match)
    } else {
      const result = await fetchMatchingCombo()
      if (result.data) {
        const match = mapResponseToMatch(result.data)
        setFoundMatch(match)
      } else {
        setErrorCombo('comboID', { message: 'No records to display.' })
      }
    }
  }

  useEffect(() => {
    if (isModalOpen) {
      // When opening the modal, reset the form
      resetCoverage()
      resetCombo()
    }
  }, [isModalOpen, resetCoverage, resetCombo])

  return doesPolicyHaveMatch ? (
    <>
      <PhoenixBaseCard cardTitle={'Match to'} variantType={'Secondary'}>
        <Box p={1} width={'100%'}>
          <Box display="flex" justifyContent="space-between" alignItems="flex-start">
            <FieldGroup label={'Combo ID'} value={match?.combo?.identifier} />
            <Button
              variant="text"
              onClick={() => {
                setFoundMatch(null)
                resetCoverage()
                resetCombo()
              }}
            >
              RESET
            </Button>
          </Box>
          <FieldGroup label={'Coverage ID'} value={match?.coverage?.identifier} />
          <FieldGroup icon={<Person />} label={'Primary Name'} value={match?.employer} />
          <FieldGroup icon={<LocationOn />} label={'Address'} value={match?.address} />
          <FieldGroup icon={<WorkIcon />} label={'FEIN'} value={match?.fein} />
        </Box>
      </PhoenixBaseCard>
    </>
  ) : (
    <>
      <PhoenixBaseCard cardTitle={'Match to Existing Coverage Group'} variantType={'Secondary'}>
        <Box mx={1} my={2} sx={{ width: '100%' }}>
          <form onSubmit={handleSubmitCoverage(onSubmitFindMatchingCoverage)}>
            <Grid container>
              <Grid item xs={10}>
                <Controller
                  name="coverageID"
                  control={controlCoverage}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      {...registerCoverage('coverageID', {
                        maxLength: {
                          message: 'Coverage ID must not contain more than 7 characters.',
                          value: 7,
                        },
                        required: {
                          message: 'Please enter a value.',
                          value: true,
                        },
                        pattern: {
                          message: 'Non numeric characters are not allowed.',
                          value: /^[0-9]*$/,
                        },
                      })}
                      inputProps={{ type: 'search' }}
                      fullWidth
                      label="Coverage ID"
                      size={'small'}
                      variant={'outlined'}
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={2}>
                <LoadingButton
                  data-testid={'find-match-coverage'}
                  loading={isFetchingMatchingCoverage}
                  variant={'contained'}
                  sx={{
                    padding: '6px',
                    margin: '2px',
                    marginLeft: 1,
                    minWidth: '30px',
                  }}
                  type="submit"
                >
                  <ChevronRightIcon />
                </LoadingButton>
              </Grid>
            </Grid>
          </form>
        </Box>
      </PhoenixBaseCard>
      <Grid container py={2}>
        <Grid item xs={5} alignContent={'center'}>
          <Divider />
        </Grid>
        <Grid item xs={2} textAlign={'center'}>
          <Typography variant={'overline'}>OR</Typography>
        </Grid>
        <Grid item xs={5} alignContent={'center'}>
          <Divider />
        </Grid>
      </Grid>
      <PhoenixBaseCard cardTitle={'Create Coverage Group'} variantType={'Secondary'}>
        <Box mx={1} mt={1.5} width={'100%'}>
          <form onSubmit={handleSubmitCombo(onSubmitFindMatchingCombo)}>
            <Stack flexDirection={'row'} alignItems={'center'} mb={1}>
              <InfoIcon color={'secondary'} sx={{ mr: 1 }} />
              <Typography variant={'caption'} lineHeight={1.5}>
                The system will automatically assign a Combo ID if one is not entered.
              </Typography>
            </Stack>
            <Grid container>
              <Grid item xs={10}>
                <Controller
                  name="comboID"
                  control={controlCombo}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      {...registerCombo('comboID', {
                        maxLength: {
                          message: 'Combo ID must not contain more than 9 characters.',
                          value: 9,
                        },
                        pattern: {
                          message: 'Non numeric characters are not allowed.',
                          value: /^[0-9]*$/,
                        },
                      })}
                      inputProps={{ type: 'search' }}
                      fullWidth
                      label="Combo ID"
                      size={'small'}
                      variant={'outlined'}
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message || 'Optional'}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={2}>
                <LoadingButton
                  data-testid={'find-match-combo'}
                  variant={'contained'}
                  loading={isFetchingMatchingCombo}
                  sx={{
                    padding: '6px',
                    margin: '2px',
                    marginLeft: 1,
                    minWidth: '30px',
                  }}
                  type="submit"
                >
                  <ChevronRightIcon />
                </LoadingButton>
              </Grid>
            </Grid>
          </form>
        </Box>
      </PhoenixBaseCard>
    </>
  )
}

export default FindMatchForms
