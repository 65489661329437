import { useIdleTimer } from 'react-idle-timer'
import './App.css'
import AppRouter from './AppRouter'
import { msalInstance } from './utils/msalSetup'
import logoutActiveUser from './utils/signout'

const App = () => {
  const onIdle = () => {
    logoutActiveUser(msalInstance)
  }

  useIdleTimer({
    onIdle,
    timeout: 2 * 60 * 60 * 1000, //2 hr idle timeout
  })

  return (
    <div>
      <AppRouter />
    </div>
  )
}

export default App
