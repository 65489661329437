import { useState } from 'react'
import { useBoolean } from './useBoolean'

interface AttachmentsModal {
  isModalOpen: boolean
  openModal: (attachments: AttachmentsProps[]) => void
  closeModal: () => void
  currentAttachments: AttachmentsProps[]
}

interface AttachmentsProps {
  name: string
  path: string
}

const useAttachmentsModal = (): AttachmentsModal => {
  const [currentAttachments, setCurrentAttachments] = useState<AttachmentsProps[]>([])
  const { value, onTrue, onFalse } = useBoolean()

  const openModal = (attachments: AttachmentsProps[]) => {
    onTrue()
    setCurrentAttachments(attachments)
  }

  const closeModal = () => {
    onFalse()
  }

  return {
    isModalOpen: value,
    openModal,
    closeModal,
    currentAttachments,
  }
}

export default useAttachmentsModal
