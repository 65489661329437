import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useState } from 'react'
import { CacheKeys } from '../cache/cacheKeys'
import { AlertSeverity } from '../components/AlertNotification/AlertNotification'
import { useAlertContext } from '../contexts/AlertContext'
import { MovePolicyResponseModel } from '../models/MovePolicyResponse'
import { Policy } from '../models/Policy'
import { SuggestedMatchModel } from '../models/WorkItemDetails/SuggestedMatchModel'
import { movePoliciesToNewCoverage } from '../services/policyService'
import { HierarchyTypes } from '../utils/hierarchy.enum'
import { useBoolean } from './useBoolean'

export interface IUseMovePolicyToCoverage {
  isModalOpen: boolean
  openModal: (suggestedTransaction?: SuggestedMatchModel) => void
  closeModal: () => void
  setFoundMatch: (match: SuggestedMatchModel | null) => void
  match: SuggestedMatchModel | null
  successMove: MovePolicyResponseModel[] | null
  isMatchLoading: boolean
  isAtConfirm: boolean
  handleConfirmMove: () => void
  policiesToMove: Policy[] | null
  setPoliciesToMove: (policies: Policy[]) => void
}

interface MovePolicyToCoverageProps {
  coverage: {
    guid: string | undefined
    identifier: string | undefined
  }
}

export const useMovePolicyToCoverage = ({ coverage }: MovePolicyToCoverageProps): IUseMovePolicyToCoverage => {
  const { value: isModalOpen, onTrue, onFalse } = useBoolean()
  const [match, setMatch] = useState<SuggestedMatchModel | null>(null)
  const [successMove, setSuccessMove] = useState<MovePolicyResponseModel[] | null>(null)
  const { setDetails: setAlertDetails } = useAlertContext()
  const [isAtConfirm, setIsAtConfirm] = useState(false)
  const [policiesToMove, setPoliciesToMove] = useState<Policy[] | null>([])
  const [policyGuids, setPolicyGuids] = useState<string[]>([])
  const queryClient = useQueryClient()

  const { mutate: movePolicyFnc, isPending: isMatchLoading } = useMutation({
    mutationKey: [CacheKeys.MovePoliciesToNewCoverage, match?.coverage.guid],
    mutationFn: () => movePoliciesToNewCoverage(match?.coverage.guid || null, match?.combo.guid || null, policyGuids),
    onError: () => {
      return setAlertDetails({ message: 'Unable to move policies to coverage', severity: AlertSeverity.ERROR })
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: [CacheKeys.PolicyListByCoverageId, coverage.guid] })
      queryClient.invalidateQueries({ queryKey: [CacheKeys.PolicyListByCoverageId, match?.coverage.guid] })
      policyGuids.forEach((guid) => {
        queryClient.invalidateQueries({ queryKey: [CacheKeys.Ancestors, guid, HierarchyTypes.POLICY] })
      })
      // queryClient.invalidateQueries()
      setIsAtConfirm(true)
      setSuccessMove(data)
    },
  })

  const mapPoliciesToGuids = (policies: Policy[]) => {
    setPolicyGuids(policies.map((policy) => policy.policyId))
  }
  const handleConfirmMove = async () => {
    if (!match || !policiesToMove) return
    if (match.coverage.identifier == coverage.identifier) {
      setAlertDetails({ message: 'Unable to move policies to the same coverage', severity: AlertSeverity.ERROR })
      return
    }
    mapPoliciesToGuids(policiesToMove)
    movePolicyFnc()
  }

  const openModal = (suggestedTransaction?: SuggestedMatchModel) => {
    if (suggestedTransaction) {
      setMatch(suggestedTransaction)
    } else {
      setMatch(null)
    }
    setIsAtConfirm(false)
    onTrue()
  }

  const closeModal = () => {
    onFalse()
  }

  const setFoundMatch = (match: SuggestedMatchModel | null) => {
    setMatch(match)
  }

  return {
    isModalOpen,
    openModal,
    closeModal,
    setFoundMatch,
    match,
    successMove,
    isMatchLoading,
    isAtConfirm,
    handleConfirmMove,
    policiesToMove,
    setPoliciesToMove,
  }
}
