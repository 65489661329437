import { useQuery } from '@tanstack/react-query'
import { ComboCoverageResponse } from '../models/CoverageGroupModels'
import { getCoverageDataByCoverageId } from '../services/coverageService'
import { CacheKeys } from './cacheKeys'
import { StaleTime } from './staleTime'

const useGetCoverageDataByComboId = (comboId: string, coverageId?: string) => {
  return useQuery<ComboCoverageResponse>({
    queryKey: [CacheKeys.CoverageDataByComboID, comboId, coverageId],
    queryFn: () => getCoverageDataByCoverageId(String(comboId), coverageId),
    staleTime: StaleTime.THIRTY_MINUTES,
    enabled: !!comboId,
  })
}

export { useGetCoverageDataByComboId }
