import { Grid } from '@mui/material'
import React, { Children } from 'react'

interface LayoutProps {
  mainContent?: React.ReactNode | React.JSX.Element
  sideRailComponentArray?: Array<React.ReactNode | React.JSX.Element>
}

const LayoutWithSideRail = ({ mainContent, sideRailComponentArray }: LayoutProps) => {
  return (
    <Grid container spacing={0}>
      <Grid item xs={2}>
        {Children.toArray(sideRailComponentArray).map((child, index) => {
          return (
            <div style={{ marginBottom: '12px' }} key={index}>
              {child}
            </div>
          )
        })}
      </Grid>
      <Grid
        item
        xs={10}
        sx={{
          marginTop: '16px',
          marginBottom: '24px',
          paddingLeft: '16px',
        }}
      >
        {mainContent}
      </Grid>
    </Grid>
  )
}

export default LayoutWithSideRail
