/* eslint-disable react/prop-types */
import { Box, Button } from '@mui/material'
import { PhoenixBaseTable } from 'componix'
import { DataFieldBlankValue, PhoenixLink } from 'componix'
import { MRT_ColumnDef } from 'material-react-table'
import { SuggestedMatchModel } from '../../models/WorkItemDetails/SuggestedMatchModel'
import { handleTitleCase } from '../../utils/handleTitleCase'

const SuggestedMatchesColumns: MRT_ColumnDef<SuggestedMatchModel>[] = [
  {
    accessorKey: 'combo.identifier',
    header: 'Combo ID',
    /* eslint-disable  @typescript-eslint/no-explicit-any */
    Cell: ({ cell, row }) => (
      <>
        {row.original.combo.guid ? (
          <PhoenixLink
            id={`Combo-${row.index}`}
            to={`/combos/${row.original.combo.guid}`}
            value={cell.getValue<string>()}
          />
        ) : (
          <DataFieldBlankValue />
        )}
      </>
    ),
  },
  {
    accessorKey: 'coverage.identifier',
    header: 'Coverage ID',
    /* eslint-disable  @typescript-eslint/no-explicit-any */
    Cell: ({ cell, row }) => (
      <>
        {cell.getValue() ? (
          <PhoenixLink
            id={`Coverage-${row.index}`}
            to={`/coverages/${row.original.coverage.identifier}`}
            value={cell.getValue<string>()}
          />
        ) : (
          <DataFieldBlankValue />
        )}
      </>
    ),
  },
  {
    accessorKey: 'employer',
    header: 'Employer',
    Cell: ({ cell }: any) => {
      return handleTitleCase(cell.getValue())
    },
  },
  {
    accessorKey: 'address',
    header: 'Address',
    Cell: ({ cell }: any) => {
      return handleTitleCase(cell.getValue())
    },
  },
  {
    accessorKey: 'fein',
    header: 'FEIN',
  },
]

interface SuggestedMatchesTableProps {
  matches: SuggestedMatchModel[]
  onMatchClick?: (match: SuggestedMatchModel) => void
}

const SuggestedMatchesTable = ({ matches, onMatchClick }: SuggestedMatchesTableProps) => {
  return (
    <PhoenixBaseTable
      columns={SuggestedMatchesColumns}
      data={matches}
      overrides={{
        enableRowActions: true,
        positionActionsColumn: 'last',
        //@ts-ignore
        renderRowActions: ({ row }) => (
          <Box display={'flex'} justifyContent={'flex-end'} pr={'4px'}>
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={() => onMatchClick && onMatchClick(row.original)}
            >
              Match
            </Button>
          </Box>
        ),
      }}
    />
  )
}

export default SuggestedMatchesTable
