export const handleTitleCase = (str: string | undefined) => {
  if (!str) return ''
  try {
    if (str.length === 0) return str
    const stateAbbreviations = [
      'AL',
      'AK',
      'AZ',
      'AR',
      'CA',
      'CO',
      'CT',
      'DE',
      'FL',
      'GA',
      'HI',
      'ID',
      'IL',
      'IN',
      'IA',
      'KS',
      'KY',
      'LA',
      'ME',
      'MD',
      'MA',
      'MI',
      'MN',
      'MS',
      'MO',
      'MT',
      'NE',
      'NV',
      'NH',
      'NJ',
      'NM',
      'NY',
      'NC',
      'ND',
      'OH',
      'OK',
      'OR',
      'PA',
      'RI',
      'SC',
      'SD',
      'TN',
      'TX',
      'UT',
      'VT',
      'VA',
      'WA',
      'WV',
      'WI',
      'WY',
    ]
    const abbreviations = ['LLC', 'INC', 'PO', 'CORP', 'DBA', 'PC', 'LTD']
    abbreviations.push(...stateAbbreviations)
    return str
      .split(' ')
      .map((word) => {
        if (abbreviations.includes(word)) {
          return word
        }
        return word.charAt(0).toUpperCase() + word.slice(1).toLocaleLowerCase()
      })
      .join(' ')
    // eslint-disable-next-line
  } catch (exception) {
    return ''
  }
}
