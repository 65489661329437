import { Grid } from '@mui/material'
import { FieldGroup } from 'componix'
import { UnitStatCarrierErrorResponseModel } from '../../../models/WorkItemDetails/CarrierErrorNotification'

interface USRCarrierErrorHeaderProps {
  error: UnitStatCarrierErrorResponseModel | undefined
}

export const USRCarrierErrorHeader = ({ error }: USRCarrierErrorHeaderProps) => {
  return (
    <Grid container padding={'8px'}>
      <Grid item xs={3}>
        <FieldGroup label="Notice Date" value={error?.noticeDate}></FieldGroup>
      </Grid>
      <Grid item xs={3}>
        <FieldGroup label="Fine Due Date" value={error?.fineDueDate}></FieldGroup>
      </Grid>
      <Grid item xs={3}>
        <FieldGroup label="Fine Status" value={error?.fineStatus}></FieldGroup>
      </Grid>
      <Grid item xs={3}>
        <FieldGroup label="Fine Status Date" value={error?.fineStatusDate}></FieldGroup>
      </Grid>
    </Grid>
  )
}
