import { CombinedCoverageHistoryModel } from '../models/CombinedCoverageHistory'
import { Combo } from '../models/Combo'
import { RatingHistoryDTO } from '../models/RatingHistoryDTO'
import { SearchByComboIDResponse } from '../models/SearchByComboIDResponse'
import api, { ApiResponse } from '../utils/apiService'
import { Endpoints } from '../utils/endpoint'

export const getRatingHistory = async (comboId: string) => {
  const response: ApiResponse<RatingHistoryDTO[]> = await api.get(`${Endpoints.Combos}/${comboId}/ratings`)
  return response.data
}

export const getComboInfo = async (comboId: string) => {
  const response: ApiResponse<Combo> = await api.get(`${Endpoints.Combos}/${comboId}`)
  return response.data
}
export const searchByComboID = async (comboId: string) => {
  const response: ApiResponse<SearchByComboIDResponse> = await api.get(`${Endpoints.Combos}/SearchByComboId/${comboId}`)
  if (response.status === 204) {
    return {}
  }
  return response.data
}

export const getCombinedCoverageHistory = async (comboId: string) => {
  const response: ApiResponse<CombinedCoverageHistoryModel[]> = await api.get(
    `${Endpoints.Combos}/${comboId}/CombinedCoverageHistory`
  )
  return response.data
}
